const devopsRoutes = [
  {
    path: 'devops/document',
    name: 'Document',
    meta: {
      title: '知识库',
      icon: 'document',
      requireApiPermission: ['base.document.view']
    },
    component: () => import('@/views/Document')
  },
  {
    path: 'devops/automation',
    name: 'Automation',
    meta: {
      title: '自动化',
      icon: 'auto_mation',
      requireModule: 'ops'
    },
    component: () => import('@/layouts/BlankLayout'),
    children: [
      {
        path: 'script-task',
        name: 'ScriptExecute',
        meta: {
          title: '脚本执行',
          requireApiPermission: ['ops.script_task.create']
        },
        component: () => import('@/views/script-execute/ScriptExecute')
      },
      {
        path: 'script-task/:id/detail',
        name: 'ScriptExecuteDetail',
        meta: {
          title: '执行详情',
          hidden: true,
          requireApiPermission: ['ops.script_task.view']
        },
        component: () => import('@/views/script-execute/ExecuteDetail')
      },
      {
        path: 'script-task/history',
        name: 'ScriptExecuteHistory',
        meta: {
          title: '执行历史',
          requireApiPermission: ['ops.script_task.view']
        },
        component: () => import('@/views/script-execute/HistoryList')
      }
    ]
  }
]

export default devopsRoutes
