export const sourceTypes = [
  'network_device',
  'terminal_device',
  'storage',
  'server',
  'os',
  'hypervisor',
  'middleware',
  'database',
  'containerization',
  'application',
  'domain',
  'site',
  'ssl_certificate'
]

export const alertSourceTypes = [
  'network_device',
  'storage',
  'server',
  'os',
  'middleware',
  'database'
]

export const securityType = [
  'firewall',
  'load_balancer',
  'security'
]

export const sourceStatuses = [
  'normal',
  'abnormal',
  'alert',
  'unknown'
]

export const snmpVersions = [
  'snmp_v1',
  'snmp_v2c',
  'snmp_v3'
]

export const snmpSecurityLevels = [
  'no_auth_no_priv',
  'auth_no_priv',
  'auth_priv'
]

export const snmpAuthProtocols = [
  'md5',
  'sha1',
  'sha224',
  'sha256',
  'sha384',
  'sha512'
]

export const snmpPrivacyProtocols = [
  'des',
  'aes128',
  'aes192',
  'aes256',
  'aes192c',
  'aes256c'
]

export const networkDeviceTypes = [
  'unknown',
  'firewall',
  'switch',
  'router',
  'load_balancer',
  'access_controller',
  'security'
]

export const terminalDeviceTypes = [
  'unknown',
  'sensor',
  'cctv'
]

export const osTypes = [
  'linux',
  'windows'
]

export const databaseTypes = [
  'mysql',
  'postgresql',
  'oracle',
  'mssql',
  'redis',
  'memcached',
  'mongodb',
  'elasticsearch',
  'dameng',
  'clickhouse'
]

export const middlewareTypes = [
  'nginx',
  'iis',
  'php_fpm',
  'rabbitmq',
  'apache',
  'tomcat',
  'kafka',
  'zookeeper',
  'tongweb',
  'tonghttpserver'
]

export const hypervisorTypes = [
  'esxi',
  'vcenter'
]

export const containerizationTypes = [
  'docker',
  'kubernetes'
]

export const alertSeverities = [
  'disaster',
  'high',
  'average',
  'warning'
]

export const sourceTargets = [
  'all',
  'group',
  'selected'
]

export const notificationMethods = [
  'email',
  'sms',
  'phone',
  'dingding',
  'wecom'
  // 'webhook'
]

export const ipSubnetScanStatuses = [
  'unscanned',
  'scanning',
  'scanned'
]

export const requestMethods = [
  'post',
  'put',
  'delete'
]

export const blueColor = '#096DD9'
export const grayColor = '#d9d9d9'
export const greenColor = '#52c41a'
export const redColor = '#ff4d4f'
export const orangeColor = '#faad14'

export const chartColors = [
  blueColor,
  '#00b5ff',
  '#00d1ed',
  '#00e0ca',
  '#76e76e',
  '#f2e65e',
  '#ffc75f',
  '#ff9671',
  '#ff6f91',
  '#d65db1',
  '#845ec2'
]

export const top5Colors = [
  '#ffa040',
  '#ea6900',
  '#f8593e',
  '#d23622',
  '#ac0305'
]

export const assetTypes = [
  'network_device',
  'storage',
  'server'
]

export const assetStatuses = [
  'in_use',
  'in_warehouse',
  'scrap',
  'unknown'
]

export const siteProtocols = [
  'https',
  'http'
]

export const compareConditions = [
  'less_than',
  'less_than_or_equal_to',
  'equal_to',
  'not_equal_to',
  'greater_than',
  'greater_than_or_equal_to'
]

export const patrolStrategyStatus = [
  'waiting',
  'running',
  'finished',
  'inactive'
]

export const expirationTime = [
  'expired',
  'expires_in_1m',
  'expires_in_3m'
]

export const backgroundColors = [
  '#f1f9ff',
  '#f3ffff',
  '#f4fff3',
  '#fefef0',
  '#fff7f5',
  '#f8f3ff'
]

export const statusColors = {
  normal: [greenColor, '#f5fbf2'],
  abnormal: [redColor, '#fff5f4'],
  unknown: ['#9f9f9f', '#f9f9f9'],
  alert: [orangeColor, '#fffaf2']
}

export const DEFAULT_MENU_MODULES = [
  { name: '展示中心', key: 'display', headerIcon: 'display' },
  { name: '监控中心', key: 'monitoring', headerIcon: 'monitoring' },
  { name: '报表中心', key: 'report', headerIcon: 'report' },
  { name: '运维中心', key: 'devops', headerIcon: 'devops' },
  { name: '日志中心', key: 'log', headerIcon: 'log' },
  { name: '配置中心', key: 'setting', headerIcon: 'setting' }
]

export const logTypes = [
  'middleware_nginx_access_log'
]

export const logAggregationMethods = ['count', 'sum', 'avg', 'percentile_95th']
