import { Graph, Node } from '@antv/x6'
import SourceNode from '@/components/SourceNode'
// import NetworkNode from '@/components/NetworkNode'
import { getNetworkDeviceNetworkInterfaceList } from '@/api/network-device'
import { getServerInterfaceList } from '@/api/server'
import { getStorageInterfaceList } from '@/api/storage'
// import { blueColor } from '@/utils/const'

export function initTopology () {
  Graph.registerVueComponent(
    'source-node',
    {
      template: '<source-node></source-node>',
      components: {
        SourceNode
      }
    },
    true
  )
  // Graph.registerVueComponent(
  //   'network-node',
  //   {
  //     template: '<network-node></network-node>',
  //     components: {
  //       NetworkNode
  //     }
  //   }
  // )
  Graph.registerNode('group', Group)
}

export class Group extends Node {
  constructor (x = 0, y = 0) {
    super(x, y)
    this.collapsed = false
    this.expandSize = {}
  }

  postprocess () {
    this.toggleCollapse(false)
  }

  toggleCollapse (collapsed) {
    const target = collapsed == null ? !this.collapsed : collapsed
    if (target) {
      this.attr('buttonSign', {
        d: 'M 1 5 9 5 M 5 1 5 9'
      })
      this.expandSize = this.getSize()
      this.resize(80, 40)
    } else {
      this.attr('buttonSign', {
        d: 'M 2 5 8 5'
      })
      if (this.expandSize) { this.resize(this.expandSize.width, this.expandSize.height) }
    }
    this.collapsed = target
  }
}

Group.config({
  shape: 'group',
  width: 48,
  height: 48,
  markup: [
    {
      tagName: 'rect',
      selector: 'body'
    },
    {
      tagName: 'text',
      selector: 'label'
    },
    {
      tagName: 'g',
      selector: 'buttonGroup',
      children: [
        {
          tagName: 'rect',
          selector: 'button'
        },
        {
          tagName: 'path',
          selector: 'buttonSign'
        }
      ]
    }
  ],
  attrs: {
    body: {
      fill: '#1890ff10',
      stroke: '#1890ff10',
      refWidth: '100%',
      refHeight: '100%',
      strokeWidth: 1,
      strokeDasharray: 0
    },
    label: {
      refY: 18,
      refX: 10,
      fontSize: 13,
      fontWeight: 400,
      fill: '#1890ff90',
      text: '群组'
    }
  }
})

// export function updateGroupNodeAttrs (node) {
//   node.attr('label', {
//     refY: 5,
//     refX: 20,
//     fontSize: 14,
//     fill: blueColor,
//     text: '群组'
//   })
//   node.attr('buttonGroup', {
//     refX: 4,
//     refY: 4
//   })
//   node.attr('button', {
//     height: 14,
//     width: 14,
//     rx: 4,
//     ry: 4,
//     fill: blueColor,
//     cursor: 'pointer',
//     event: 'node:collapse'
//   })
//   node.attr('buttonSign', {
//     refX: 2,
//     refY: 2,
//     stroke: '#e8e8e8'
//   })
// }

const portAttrs = {
  circle: {
    r: 4,
    magnet: true,
    stroke: 'rgba(0, 0, 0, 0.15)',
    strokeWidth: 0.5,
    style: {
      visibility: 'hidden'
    }
  }
}

const ports = {
  groups: {
    top: {
      attrs: portAttrs,
      position: 'top'
    },
    bottom: {
      attrs: portAttrs,
      position: 'bottom'
    },
    left: {
      attrs: portAttrs,
      position: 'left'
    },
    right: {
      attrs: portAttrs,
      position: 'right'
    }
  },
  items: [
    {
      group: 'top'
    },
    {
      group: 'bottom'
    },
    {
      group: 'left'
    },
    {
      group: 'right'
    }
  ]
}

export function createTextNode (graph, name) {
  return graph.createNode({
    width: 48,
    height: 48,
    attrs: {
      label: {
        text: name,
        fontWeight: 400,
        fill: '#575757'
      },
      body: {
        fill: 'transparent',
        stroke: 'transparent'
      }
    },
    ports
  })
}

export function createSourceNode (graph, name, sourceType, sourceId, status) {
  return graph.createNode({
    shape: 'vue-shape',
    width: 48,
    height: 48,
    component: 'source-node',
    data: {
      name,
      sourceId,
      sourceType,
      status
    },
    ports
  })
}

// 网络拓扑 相关配置
export const NODE_WIDTH = 64
export const NODE_HEIGHT = 64
// 注册网络拓扑节点
export function createNetworkNode (graph, name, sourceType, sourceId, deviceType, deviceTypeName, status) {
  return graph.createNode({
    shape: 'vue-shape',
    width: 50,
    height: 50,
    component: 'network-node',
    data: {
      name,
      sourceId,
      sourceType,
      status,
      deviceType,
      deviceTypeName
    },
    ports: []
  })
}

// 渲染时相关操作
// 1.获取节点链接桩
export function syncNodesStyle (nodes) {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]

    if (node.ports === undefined) {
      if (node.shape === 'vue-shape') {
        node.width = NODE_WIDTH
        node.height = NODE_HEIGHT
        node.ports = JSON.parse(JSON.stringify(networkTopologyPortsGroup))
      }
      // 判断 deviceTypeName
      if (node.data.deviceTypeName) {
        if (node.sourceType === 'network_device') {
          node.data.deviceTypeName = this.$t(`network_device_type.${node.data.device}`)
        } else {
          node.data.deviceTypeName = this.$t(`source_type.${node.sourceType}`)
        }
      }
    }
  }
}

// 1.链接桩配置
export const networkTopologyPortsGroup = {
  groups: {
    temporary: {
      position: {
        name: 'line',
        args: {
          start: { x: 0.5, y: 0.58 },
          end: { x: 0.5, y: 0.58 }
        }
      },
      attrs: {
        circle: {
          r: 5,
          magnet: true,
          stroke: '#5da7ea',
          strokeWidth: 2,
          fill: '#fff'
        }
      }
    },
    default: {
      position: {
        name: 'line',
        args: {
          start: { x: 0.25, y: 0.15 },
          end: { x: 0.75, y: 0.65 }
          // start: { x: 0.5, y: 0.3 },
          // end: { x: 0.5, y: 0.3 }

        }
      },
      attrs: {
        circle: {
          r: 5,
          magnet: true,
          stroke: '#5da7ea',
          strokeWidth: 0,
          fill: 'transparent'
        }
      },
      zIndex: -999
    }
  },
  items: []
}

// 2.边样式配置
export const networkTopologyEdgeAttrs = {
  router: {
    name: 'normal',
    args: {
      startDirections: ['bottom'],
      endDirections: ['top']
    }
  },
  attrs: {
    line: {
      stroke: '#5da7ea',
      strokeDasharray: [8, 6],
      strokeWidth: 1,
      targetMarker: 'path',
      style: {
        animation: 'topology-edge 50s infinite linear'
      }
    }
  },
  zIndex: -9
}

function getEdgeWidth (rate) {
  if (rate >= 90) return 5
  if (rate >= 80) return 3
  return 1
}

// 3.添加节点
export function networkTopologyAddNodes (graph, nodes) {
  nodes.forEach(node => {
    if (node.shape === 'vue-shape') {
      node.width = NODE_WIDTH
      node.height = NODE_HEIGHT
      node.ports = JSON.parse(JSON.stringify(networkTopologyPortsGroup))
    }
    graph.addNode(node)
  })
}

// 4.添加边
export function networkTopologyAddEdge (graph, infos) {
  const { edge } = { ...infos }
  graph.addEdge({
    ...networkTopologyEdgeAttrs,
    source: edge[0],
    target: edge[1]
  })
}

// 5.根据告警修改边样式
function getStatusColor (status) {
  let color = '#d9d9d9'
  if (status === 'normal') color = '#31d0c6'
  if (status === 'alert') color = '#ffa040'
  if (status === 'abnormal') color = '#f8593e'
  return {
    type: 'linearGradient',
    stops: [
      { offset: '0%', color: `${color}90` },
      { offset: '100%', color: color }
    ]
  }
}
export function networkTopologyEdgeStyle (edge) {
  const sNode = edge.getSourceNode()
  const source = {
    sourceId: sNode.data.sourceId,
    portId: edge.source.port
  }
  const tNode = edge.getTargetNode()
  const target = {
    sourceId: tNode.data.sourceId,
    portId: edge.target.port
  }

  let sourceStatus = 'unknown'
  let color = '#5da7ea'
  let rate = 0
  let targetStatus = 'unknown'
  const node1Func = getInterfaceFunc(sNode.data.sourceType)
  const node1 = node1Func(source.sourceId, {
    network_interface_id: source.portId
  }).then(res => {
    const data = res.data.data
    if (data.length) {
      sourceStatus = data[0].status
      if (data[0].net_if_in && data[0].net_if_out) {
        const max = data[0].net_if_in > data[0].net_if_out ? data[0].net_if_in : data[0].net_if_out
        if (max !== 0 && data[0].speed !== 0) {
          rate = max / rate * 100
        }
      }
    } else {
      sourceStatus = 'unknown'
    }
  }).catch(() => { })
  const node2Func = getInterfaceFunc(tNode.data.sourceType)
  const node2 = node2Func(target.sourceId, {
    network_interface_id: target.portId
  }).then(res => {
    targetStatus = res.data.data.length ? res.data.data[0].status : 'unknown'
  }).catch(() => { })

  Promise.all([node1, node2]).then(() => {
    if (targetStatus === sourceStatus) {
      color = getStatusColor(sourceStatus)
    } else {
      color = getStatusColor('alert')
    }
    edge.setAttrs({
      line: {
        ...networkTopologyEdgeAttrs.line,
        stroke: color,
        strokeWidth: getEdgeWidth(rate)
      }
    })
  })
}

function getInterfaceFunc (type) {
  if (type === 'server') return getServerInterfaceList
  if (type === 'storage') return getStorageInterfaceList
  return getNetworkDeviceNetworkInterfaceList
}

// 6.更改网口状态
export function changeInterfaceDisabled (graph, edge, disabled) {
  const sNode = graph.getCell(edge.getSource().cell)
  const tNode = graph.getCell(edge.getTarget().cell)
  if (sNode !== null) {
    const sPortId = edge.source.port
    sNode.setPortProp(sPortId, 'disabled', disabled)
  }
  if (tNode !== null) {
    const tPortId = edge.target.port
    tNode.setPortProp(tPortId, 'disabled', disabled)
  }
}

// 7.画布现有port添加到node中
export function initAddPorts (edge) {
  const sNode = edge.getSourceNode()
  const sourcePortId = edge.source.port
  const sourceArr = []
  if (!sNode.hasPort(sourcePortId) && sourceArr.findIndex(item => item === sourcePortId) === -1) {
    sourceArr.push(sourcePortId)
    getNetworkDeviceNetworkInterfaceList(sNode.data.sourceId, {
      network_interface_id: sourcePortId
    }).then(res => {
      const data = res.data.data[0]
      sNode.addPort({
        id: data.id,
        name: data.name,
        group: 'default',
        disabled: true
      })
    }).catch(() => { })
  }

  const tNode = edge.getTargetNode()
  const targetPortId = edge.target.port
  const targetArr = []
  if (!tNode.hasPort(targetPortId) && targetArr.findIndex(item => item === targetPortId) === -1) {
    targetArr.push(targetPortId)
    getNetworkDeviceNetworkInterfaceList(tNode.data.sourceId, {
      network_interface_id: targetPortId
    }).then(res => {
      const data = res.data.data[0]
      tNode.addPort({
        id: data.id,
        name: data.name,
        group: 'default',
        disabled: true
      })
    }).catch(() => { })
  }
}

// 8.自动生成拓扑时 将边添加到画布 并把画布现有port添加到node中
export function initAddEdge (graph, edge, onlyDisplay = false) {
  const newEdge = graph.addEdge({
    ...networkTopologyEdgeAttrs,
    source: edge.source,
    target: edge.target
  })
  networkTopologyEdgeStyle(newEdge) // 根据告警修改样式
  // initAddPorts(newEdge) // 添加ports
}
