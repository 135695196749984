import axios from 'axios'
import message from 'ant-design-vue/es/message'
import Cookies from 'js-cookie'
import storage from 'store'
import config from '@/config'
import store from '@/store'
// import router from '@/router'

const request = axios.create({
  baseURL: `${config.baseURL}/api/v1`,
  timeout: config.timeout,
  withCredentials: true
})

function handler (data) {
  message.error(data.message)
  if (data.code === 401) {
    const accessToken = storage.get('ACCESS_TOKEN')
    if (accessToken) {
      store.dispatch('Logout').then(() => {
        setTimeout(() => {
          window.location.reload()
        }, 300)
      })
    }
  // } else if (~~(data.code / 100) === 5) {
  //   // TODO: 更好地跳转 5xx
  //   router.push('/5xx')
  }
}

const errorHandler = error => {
  if (error && error.message !== '取消请求') {
    let err
    if (error.request.responseType === 'blob') {
      const reader = new FileReader()
      reader.readAsText(error.response.data, 'utf-8')
      reader.onload = () => {
        err = JSON.parse(reader.result)
        handler(err)
      }
    } else {
      err = error.response.data
      handler(err)
    }
    return Promise.reject(err)
  }
  return Promise.reject(error.message)
}

request.interceptors.request.use(value => {
  value.cancelToken = new axios.CancelToken(cancel => {
    store.commit('cancelRequest/ADD_PROMISE_ARR', { cancel })
  })
  const accessToken = storage.get('ACCESS_TOKEN')
  if (accessToken) {
    value.headers.Authorization = `Bearer ${accessToken}`
  }
  if (request.method !== 'get') {
    value.headers['X-CSRFToken'] = Cookies.get('csrf_token')
  }
  return value
}, errorHandler)

request.interceptors.response.use(value => value.data, errorHandler)

export default request
