import qs from 'qs'
import request from '@/utils/request'

const urlPrefix = '/ssl-certificates'

export function getSSLCertificate (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateSSLCertificate (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteSSLCertificate (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getSSLCertificateList (params) {
  return request.get(urlPrefix, { params })
}

export function createSSLCertificate (data) {
  return request.post(urlPrefix, data)
}

export function syncSSLCertificate (data) {
  return request.post(`${urlPrefix}/sync`, data)
}

export function exportSSLCertificate (params) {
  return request.get(`${urlPrefix}/export`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    },
    responseType: 'blob'
  })
}

export function getSSLCertificateGroup (id) {
  return request.get(`${urlPrefix}/groups/${id}`)
}

export function updateSSLCertificateGroup (id, data) {
  return request.put(`${urlPrefix}/groups/${id}`, data)
}

export function deleteSSLCertificateGroup (id) {
  return request.delete(`${urlPrefix}/groups/${id}`)
}

export function getSSLCertificateGroupList () {
  return request.get(`${urlPrefix}/groups/tree`)
}

export function createSSLCertificateGroup (data) {
  return request.post(`${urlPrefix}/groups`, data)
}

export function getSSLCertificateAlertList (params) {
  return request.get(`${urlPrefix}/alerts`, { params })
}
