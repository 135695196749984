<template>
  <a-layout class="main-layout">
    <a-layout-header
      :style="{
        background: '#fff',
        height: '60px',
        lineHeight: '60px',
        fontWeight: 600,
        padding: '0 24px 0 0px',
        borderBottom: '1px solid #e5e5e5',
        zIndex: 999,
        position: 'fixed',
        width: '100vw',
        boxShadow: '0 1px 10px rgba(0, 0, 0, 0.15)'
      }"
    >
      <div style="width: 200px; text-align: center; float: left">
        <img src="@/assets/images/logo.png" alt="Logo" style="max-width: 200px; max-height: 60px">
      </div>
      <a-menu
        :selected-keys="topMenuSelect"
        mode="horizontal"
        @select="handleTopMenuSelect"
        style="
          width: calc(100vw - 436px);
          float: left;
          border: none;
          margin-left: 24px;
          margin-top: -1px;
        "
      >
        <a-menu-item  v-for="item in headerMenuRoutes" :key="item.key">
          <header-menu-icon style="font-size: 16px;" :icon="item.headerIcon"></header-menu-icon>
          <span>{{ item.name }}</span>
        </a-menu-item>
      </a-menu>
      <div
        class="top-menu-right"
        style="width: 160px; float: right; text-align: right"
      >
        <dropdown-menu></dropdown-menu>
      </div>
    </a-layout-header>

    <a-layout style="margin-top: 60px; min-height: calc(100vh - 130px)">
      <template v-if="topMenuSelect[0] !== 'profile'">
        <collapse-button
          :collapsed="collapsed"
          :style="{
            position: 'fixed',
            bottom: '64px',
            left: collapsed ? '62px' : '180px',
            zIndex: 1000
          }"
          @click="collapsed = !collapsed"
        ></collapse-button>
        <a-layout-sider
          breakpoint="md"
          :collapsible="true"
          v-model="collapsed"
          :trigger="null"
          style="
            z-index: 998;
            background: #fff;
            position: fixed;
            height: calc(100vh - 60px);
            padding-top: 4px;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
          "
        >
          <a-menu
            mode="inline"
            :open-keys.sync="openKeys"
            :selected-keys="selectedKeys"
            theme="light"
            @select="({ key }) => $router.push({ name: key })"
          >
            <template v-for="menuItem in menuItems">
              <template v-if="menuItem.children">
                <a-sub-menu :key="menuItem.name">
                  <template slot="title">
                    <slider-menu-icon style="font-size: 16px;" v-if="menuItem.meta.icon" :icon="menuItem.meta.icon"></slider-menu-icon>
                    <span>{{ menuItem.meta.title }}</span>
                  </template>
                  <template v-for="child in menuItem.children">
                    <a-menu-item
                      v-if="child.meta.hidden !== true"
                      :key="child.name"
                    >
                      {{ child.meta.title }}
                    </a-menu-item>
                  </template>
                </a-sub-menu>
              </template>
              <template v-else>
                <a-menu-item
                  v-if="menuItem.meta.hidden !== true"
                  :key="menuItem.name"
                >
                  <slider-menu-icon style="font-size: 16px;" v-if="menuItem.meta.icon" :icon="menuItem.meta.icon"></slider-menu-icon>
                  <span>{{ menuItem.meta.title }}</span>
                </a-menu-item>
              </template>
            </template>
          </a-menu>
        </a-layout-sider>

        <a-layout-content
          :style="{
            padding: '16px 16px 0 16px',
            marginLeft: collapsed ? '80px' : '200px'
          }"
        >
          <router-view style="z-index: 3; position: relative"></router-view>
          <a-back-top></a-back-top>
        </a-layout-content>
      </template>
      <template v-else>
        <a-layout-content
          :style="{
            padding: '16px 16px 0 16px'
          }"
        >
          <router-view style="z-index: 3; position: relative"></router-view>
          <a-back-top></a-back-top>
        </a-layout-content>
      </template>
    </a-layout>
    <layout-footer
      :style="{ marginLeft: collapsed ? '80px' : '200px' }"
    ></layout-footer>
  </a-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import LayoutFooter from '@/components/LayoutFooter.vue'
import SliderMenuIcon from '@/components/icon/SliderMenuIcon'
import HeaderMenuIcon from '@/components/icon/HeaderMenuIcon'
import CollapseButton from './modules/CollapseButton'
import DropdownMenu from './modules/DropdownMenu.vue'

export default {
  name: 'MainLayout',
  components: {
    CollapseButton,
    DropdownMenu,
    LayoutFooter,
    SliderMenuIcon,
    HeaderMenuIcon
  },
  data () {
    return {
      collapsed: false,
      openKeys: [],
      cachedOpenKeys: [],
      selectedKeys: [],
      sliderMenuList: []
    }
  },
  computed: {
    ...mapGetters(['menuRoutes']),
    ...mapGetters(['headerMenuRoutes']),
    menuItems () {
      const list = this.menuRoutes.filter(item => item.path.split('/')[0] === this.topMenuSelect[0])
      return list
    },
    topMenuSelect () {
      return [this.$route.path.split('/')[1]]
    }
  },
  mounted () {
    this.updateMenu()
  },
  methods: {
    updateMenu () {
      const routes = this.$route.matched.concat()
      const openKeys = routes.map(r => r.name)
      if (this.collapsed) {
        this.cachedOpenKeys = openKeys
      } else {
        this.openKeys = openKeys
      }
      this.selectedKeys = [routes.pop().name]
    },
    handleTopMenuSelect ({ key }) {
      this.sliderMenuList = this.menuRoutes.filter(item => item.path.split('/')[0] === key)
      this.$router.push({
        name: this.sliderMenuList[0].name
      })
    }
  },
  watch: {
    collapsed (val) {
      if (val) {
        this.cachedOpenKeys = this.openKeys
        this.openKeys = []
      } else {
        this.openKeys = this.cachedOpenKeys
      }
    },
    $route () {
      this.updateMenu()
    }
  }
}
</script>

<style lang="less">
.main-layout {
  min-height: 100vh;

  .ant-menu-horizontal {
    line-height: 60px;
    font-size: 16px;
    // padding: 8px 0;
    font-weight: 500;

    & > .ant-menu-item,
     & > .ant-menu-item:hover ,
     & > .ant-menu-submenu{
      top: 0;
      border-width: 3px;
      line-height: 54px;
      margin: 0 12px;
      padding: 0 12px;
      // border: none;
      // border-radius: 6px;
    }

    & > .ant-menu-item-selected {
      // background: #096dd9 !important;
      // color: #fff !important;
      border-width: 3px;
    }
  }

  .ant-layout-sider {
    overflow: auto;
    background-color: #fff;

    .ant-layout-sider-children {
      .ant-menu-inline {
        border: none;
      }
      .ant-menu-vertical {
        padding: 0 8px;
        border: none;

        .ant-menu-submenu-title,
        .ant-menu-item {
          padding: 2px 24px !important;
          border-radius: 8px;
        }

        & > .ant-menu-item-selected,
        .ant-menu-submenu-selected .ant-menu-submenu-title {
          // color: #fff;
          background: aliceblue;
          font-weight: 500;
        }
      }
      > .ant-menu-inline {
        padding: 0 8px;

        & > .ant-menu-item,
        .ant-menu-submenu-title {
          border-radius: 8px;
        }
        & > .ant-menu-item-selected,
        .ant-menu-submenu-selected .ant-menu-submenu-title {
          // color: #fff;
          font-weight: 500;
          background: aliceblue;
        }
        .ant-menu-submenu-selected {
          .ant-menu-item-selected::before {
            content: '';
            position: absolute;
            display: block;
            left: 16px;
            top: 16px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #096dd9;
          }
        }
      }
    }
  }
}
</style>
