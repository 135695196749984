import { getLicense } from '@/api/setting'

export default {
  state: {
    systemModules: []
  },
  mutations: {
    SET_SYSTEM_MODULES: (state, systemModules) => {
      state.systemModules = systemModules
    }
  },
  actions: {
    GetSystemLicense ({ commit }) {
      return new Promise((resolve, reject) => {
        getLicense().then(res => {
          const modules = res.data.modules
          commit('SET_SYSTEM_MODULES', modules)
          resolve(modules)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
