export default {
  namespaced: true,
  state: {
    promiseArr: []
  },
  mutations: {
    ADD_PROMISE_ARR (state, item) {
      state.promiseArr.push(item)
    },
    CLEAR_PROMISE_ARR (state) {
      state.promiseArr = []
    }
  }
}
