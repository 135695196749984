import qs from 'qs'
import request from '@/utils/request'

const urlPrefix = '/servers'

export function getServer (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateServer (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteServer (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getServerFanList (id) {
  return request.get(`${urlPrefix}/${id}/fans`)
}

export function getServerPSUList (id) {
  return request.get(`${urlPrefix}/${id}/psus`)
}

export function getServerTemperatureList (id) {
  return request.get(`${urlPrefix}/${id}/temperatures`)
}

export function getServerPhysicalDiskList (id) {
  return request.get(`${urlPrefix}/${id}/physical-disks`)
}

export function getServerDiskArrayList (id) {
  return request.get(`${urlPrefix}/${id}/disk-arrays`)
}

export function getServerMemoryList (id) {
  return request.get(`${urlPrefix}/${id}/memories`)
}

export function getServerNetworkInterfaceList (id, params) {
  return request.get(`${urlPrefix}/${id}/network-interfaces`, { params })
}

export function getServerCPUList (id) {
  return request.get(`${urlPrefix}/${id}/cpus`)
}

export function getServerMonitorStatusList (id) {
  return request.get(`${urlPrefix}/${id}/monitor/statuses`)
}

export function getServerHealthStatusList (id) {
  return request.get(`${urlPrefix}/${id}/health/statuses`)
}

export function getServerMonitorItemList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/items`, { params })
}

export function getServerMonitorHistory (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/history`, { params })
}

export function getServerMonitorTriggerList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/triggers`, { params })
}

export function updateServerMonitorTrigger (id, data) {
  return request.post(`${urlPrefix}/${id}/monitor/triggers`, data)
}

export function getServerList (params) {
  return request.get(urlPrefix, { params })
}

export function createServer (data) {
  return request.post(urlPrefix, data)
}

export function getServerTemplateList (params) {
  return request.get(`${urlPrefix}/monitor/templates`, { params })
}

export function getServerCount (params) {
  return request.get(`${urlPrefix}/count`, { params })
}

export function getServerTop (params) {
  return request.get(`${urlPrefix}/top`, { params })
}

export function getAbnormalServerList (params) {
  return request.get(`${urlPrefix}/abnormal`, { params })
}

export function getPerformanceServerList (params) {
  return request.get(`${urlPrefix}/performance`, { params })
}

export function syncServer (data) {
  return request.post(`${urlPrefix}/sync`, data)
}

export function scanServer (data) {
  return request.post(`${urlPrefix}/scan`, data)
}

export function testServer (data) {
  return request.post(`${urlPrefix}/test`, data)
}

export function exportServer (params) {
  return request.get(`${urlPrefix}/export`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    },
    responseType: 'blob'
  })
}

export function importServer (data) {
  return request.post(`${urlPrefix}/import`, data)
}

export function getServerImportTemplate () {
  return request.get(`${urlPrefix}/import/template`, { responseType: 'blob' })
}

export function getServerGroup (id) {
  return request.get(`${urlPrefix}/groups/${id}`)
}

export function updateServerGroup (id, data) {
  return request.put(`${urlPrefix}/groups/${id}`, data)
}

export function deleteServerGroup (id) {
  return request.delete(`${urlPrefix}/groups/${id}`)
}

export function getServerGroupList () {
  return request.get(`${urlPrefix}/groups/tree`)
}

export function createServerGroup (data) {
  return request.post(`${urlPrefix}/groups`, data)
}

export function getServerInterfaceList (id, params) {
  return request.get(`${urlPrefix}/${id}/network-interfaces`, { params })
}

export function getServerHealthScore (id) {
  return request.get(`${urlPrefix}/${id}/health/score`)
}

export function getServerAlertList (params) {
  return request.get(`${urlPrefix}/alerts`, { params })
}
