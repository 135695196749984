export default {
  nickname: state => state.user.nickname,
  role: state => state.user.role,
  apiPermissions: state => state.user.apiPermissions,
  pagePermissions: state => state.user.pagePermissions,
  routes: state => state.permission.routes, // 过滤之后的所有路由
  menuRoutes: state => state.permission.menuRoutes, // 过滤之后的菜单
  headerMenuRoutes: state => state.permission.headerMenuRoutes,
  systemModules: state => state.license.systemModules,
  serverAddress: state => state.system.serverAddress,
  monitorActiveMode: state => state.system.monitorActiveMode,
  publicKey: state => state.system.publicKey,
  opsItsmApi: state => state.system.opsItsmApi,
  ssoDirectUrl: state => state.system.ssoDirectUrl,
  skywalkingUrl: state => state.system.skywalkingUrl
}
