import qs from 'qs'
import request from '@/utils/request'

const urlPrefix = '/sites'

export function getSite (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateSite (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteSite (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getSiteMonitorHistory (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/history`, { params })
}

export function getSiteMonitorItemList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/items`, { params })
}

export function getSiteMonitorTriggerList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/triggers`, { params })
}

export function updateSiteMonitorTrigger (id, data) {
  return request.post(`${urlPrefix}/${id}/monitor/triggers`, data)
}

export function getSiteAccessLogList (id, params) {
  return request.get(`${urlPrefix}/${id}/access-logs`, { params })
}

export function getSiteAccessLogCount (id, params) {
  return request.get(`${urlPrefix}/${id}/access-logs/count`, { params })
}

export function getSiteAccessLogTop (id, params) {
  return request.get(`${urlPrefix}/${id}/access-logs/top`, { params })
}

export function getSiteAccessLogHistory (id, params) {
  return request.get(`${urlPrefix}/${id}/access-logs/history`, { params })
}

export function getSiteList (params) {
  return request.get(urlPrefix, { params })
}

export function createSite (data) {
  return request.post(urlPrefix, data)
}

export function syncSite (data) {
  return request.post(`${urlPrefix}/sync`, data)
}

export function exportSite (params) {
  return request.get(`${urlPrefix}/export`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    },
    responseType: 'blob'
  })
}

export function getSiteGroup (id) {
  return request.get(`${urlPrefix}/groups/${id}`)
}

export function updateSiteGroup (id, data) {
  return request.put(`${urlPrefix}/groups/${id}`, data)
}

export function deleteSiteGroup (id) {
  return request.delete(`${urlPrefix}/groups/${id}`)
}

export function getSiteGroupList () {
  return request.get(`${urlPrefix}/groups/tree`)
}

export function createSiteGroup (data) {
  return request.post(`${urlPrefix}/groups`, data)
}

export function getSiteAlertList (params) {
  return request.get(`${urlPrefix}/alerts`, { params })
}
