import qs from 'qs'
import request from '@/utils/request'

const urlPrefix = '/storages'

export function getStorage (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateStorage (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteStorage (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getStorageFanList (id) {
  return request.get(`${urlPrefix}/${id}/fans`)
}

export function getStoragePSUList (id) {
  return request.get(`${urlPrefix}/${id}/psus`)
}

export function getStorageTemperatureList (id) {
  return request.get(`${urlPrefix}/${id}/temperatures`)
}

export function getStorageNetworkInterfaceList (id, params) {
  return request.get(`${urlPrefix}/${id}/network-interfaces`, { params })
}

export function getStorageEnclosureList (id) {
  return request.get(`${urlPrefix}/${id}/enclosures`)
}

export function getStorageControllerList (id) {
  return request.get(`${urlPrefix}/${id}/controllers`)
}

export function getStoragePhysicalDiskList (id) {
  return request.get(`${urlPrefix}/${id}/physical-disks`)
}

export function getStoragePoolList (id) {
  return request.get(`${urlPrefix}/${id}/pools`)
}

export function getStorageLUNList (id) {
  return request.get(`${urlPrefix}/${id}/luns`)
}

export function getStorageVolumeList (id) {
  return request.get(`${urlPrefix}/${id}/volumes`)
}

export function getStorageMonitorStatusList (id) {
  return request.get(`${urlPrefix}/${id}/monitor/statuses`)
}

export function getStorageHealthStatusList (id) {
  return request.get(`${urlPrefix}/${id}/health/statuses`)
}

export function getStorageMonitorItemList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/items`, { params })
}

export function getStorageMonitorHistory (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/history`, { params })
}

export function getStorageMonitorTriggerList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/triggers`, { params })
}

export function updateStorageMonitorTrigger (id, data) {
  return request.post(`${urlPrefix}/${id}/monitor/triggers`, data)
}

export function getStorageList (params) {
  return request.get(urlPrefix, { params })
}

export function createStorage (data) {
  return request.post(urlPrefix, data)
}

export function getStorageTemplateList (params) {
  return request.get(`${urlPrefix}/monitor/templates`, { params })
}

export function getStorageCount (params) {
  return request.get(`${urlPrefix}/count`, { params })
}

export function getStorageTop (params) {
  return request.get(`${urlPrefix}/top`, { params })
}

export function syncStorage (data) {
  return request.post(`${urlPrefix}/sync`, data)
}

export function scanStorage (data) {
  return request.post(`${urlPrefix}/scan`, data)
}

export function testStorage (data) {
  return request.post(`${urlPrefix}/test`, data)
}

export function exportStorage (params) {
  return request.get(`${urlPrefix}/export`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    },
    responseType: 'blob'
  })
}

export function getStorageImportTemplate () {
  return request.get(`${urlPrefix}/import/template`, { responseType: 'blob' })
}

export function getPerformanceStorageList (params) {
  return request.get(`${urlPrefix}/performance`, { params })
}

export function importStorage (data) {
  return request.post(`${urlPrefix}/import`, data)
}

export function getStorageGroup (id) {
  return request.get(`${urlPrefix}/groups/${id}`)
}

export function updateStorageGroup (id, data) {
  return request.put(`${urlPrefix}/groups/${id}`, data)
}

export function deleteStorageGroup (id) {
  return request.delete(`${urlPrefix}/groups/${id}`)
}

export function getStorageGroupList () {
  return request.get(`${urlPrefix}/groups/tree`)
}

export function createStorageGroup (data) {
  return request.post(`${urlPrefix}/groups`, data)
}

export function getStorageInterfaceList (id, params) {
  return request.get(`${urlPrefix}/${id}/network-interfaces`, { params })
}

export function getStorageHealthScore (id) {
  return request.get(`${urlPrefix}/${id}/health/score`)
}

export function getStorageAlertList (params) {
  return request.get(`${urlPrefix}/alerts`, { params })
}
