import monitoringRoutes from './modules/monitoring'
import displayRoutes from './modules/display'
import reportRoutes from './modules/report'
import devopsRoutes from './modules/devops'
import settingRoutes from './modules/setting'
import logRoutes from './modules/log'

export const menuRoutes = [
  ...displayRoutes,
  ...monitoringRoutes,
  ...reportRoutes,
  ...devopsRoutes,
  ...logRoutes,
  ...settingRoutes
]
