<template>
  <a-dropdown style="user-select: none">
    <div class="dropdown-container">
      <!-- <a-avatar icon="user" :size="24"></a-avatar> -->
      <div style="width: 30px; font-size: 30px;">
        <a-icon :component="component"></a-icon>
      </div>
      <div class="username-container">{{ displayName }}</div>
    </div>

    <a-menu slot="overlay" style="text-align: center;">
      <a-menu-item v-if="opsItsmApi">
        <a @click="click('ticket')">
          <a-space>
            <a-icon type="file-done"></a-icon>
            <span>工单中心</span>
          </a-space>
        </a>
      </a-menu-item>
      <a-menu-item>
        <a @click="click('profile')">
          <a-space>
            <a-icon type="profile"></a-icon>
            <span>个人中心</span>
          </a-space>
        </a>
      </a-menu-item>
      <a-menu-divider></a-menu-divider>
      <a-menu-item>
        <a @click="click('logout')">
          <a-space>
            <a-icon type="logout"></a-icon>
            <span>退出登录</span>
          </a-space>
        </a>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import storage from 'store'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DropdownMenu',
  computed: {
    ...mapGetters(['nickname', 'opsItsmApi']),
    displayName () {
      return this.nickname
    }
  },
  data () {
    return {
      component: {
        template: `
          <svg viewBox="0 0 1024 1024" width="1em" height="1em">
            <path d="M512 754.5c131.8 0 250.4 56.4 333 146.4C954.6 807 1024 667.6 1024 512 1024 229.2 794.8 0 512 0S0 229.2 0 512c0 155.6 69.4 295 179 388.9 82.6-90 201.2-146.4 333-146.4z" fill="#ECEDEF" p-id="57694"></path><path d="M593.8 761.9v20c0 45-36.8 81.8-81.8 81.8s-81.8-36.8-81.8-81.8v-20c-98.6 18-186.1 68.1-251.2 139 89.5 76.7 205.8 123.1 333 123.1 127.1 0 243.5-46.4 333-123.1-65.1-70.9-152.6-120.9-251.2-139z" fill="#57ABE4" p-id="57695"></path><path d="M512 863.7c45 0 81.8-36.8 81.8-81.8v-20c-26.5-4.9-53.9-7.4-81.8-7.4s-55.3 2.6-81.8 7.4v20c0 45 36.8 81.8 81.8 81.8z" fill="#C6CBD5" p-id="57696"></path><path d="M730.9 600.4H293.1c-14.3 0-26-11.7-26-26V239.2c0-14.3 11.7-26 26-26h437.7c14.3 0 26 11.7 26 26v335.2c0.1 14.3-11.6 26-25.9 26z" fill="#4197DF" p-id="57697"></path><path d="M298.5 535.8m-72.9 0a72.9 72.9 0 1 0 145.8 0 72.9 72.9 0 1 0-145.8 0Z" fill="#C6CBD5" p-id="57698"></path><path d="M725.5 535.8m-72.9 0a72.9 72.9 0 1 0 145.8 0 72.9 72.9 0 1 0-145.8 0Z" fill="#C6CBD5" p-id="57699"></path><path d="M607.3 307.5C581.1 321 548 329.1 512 329.1s-69.1-8.1-95.3-21.6c-65 33-109.8 100.5-109.8 178.1V569c0 109.8 89.8 199.6 199.6 199.6h11.1c109.8 0 199.6-89.8 199.6-199.6v-83.4c0-77.6-44.9-145.1-109.9-178.1z" fill="#FFFFFF" p-id="57700"></path><path d="M346.5 569l-5.8-83.4s-5.4-60.4 3.8-116.4c-23.7 32.8-37.7 73-37.7 116.4V569c0 109.8 89.8 199.6 199.6 199.6h11.1c4.8 0 9.6-0.2 14.3-0.6-103.1-7.4-185.3-94.1-185.3-199z" fill="#ECEDEF" p-id="57701"></path><path d="M660.3 243.6c0 45.4-66.4 82.2-148.3 82.2S363.7 289 363.7 243.6s66.4-82.2 148.3-82.2c39.5 0 148.3-20.4 148.3-20.4v102.6z" fill="#4197DF" p-id="57702"></path>
          </svg>
        `
      }
    }
  },
  methods: {
    ...mapActions(['Logout']),
    click (key) {
      switch (key) {
        case 'ticket':
          var urlKey = ''
          var secret = ''
          var items = storage.get('ACCESS_TOKEN').split('.')
          if (items.length === 3) {
            urlKey = items[1]
            secret = items[2]
          }
          window.open(`${this.opsItsmApi}/auth/sso?key=${urlKey}&secret=${secret}`, '_blank')
          break
        case 'profile':
          this.$router.push({ name: 'UserProfile' })
          break
        case 'logout':
          this.$confirm({
            centered: true,
            content: '要注销登录吗？',
            title: '提示',
            onOk: () => {
              this.Logout().then(() => {
                window.location.reload()
              })
            }
          })
          break
      }
    }
  }
}
</script>

<style scoped>
.dropdown-container {
  display: flex;
  width: 160px;
  overflow: hidden;
  align-items: center;
  justify-content: flex-end;
}

.username-container {
  padding-left: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 30px);
  text-align: left;
}
</style>
