<template>
  <a-tooltip :title="name" placement="bottom">
    <div
      :style="statusStyle"
    >
      <topology-source-icon
        :source-type="sourceType"
        :style="{
          fontSize: '26px',
          color: '#1890ff',
          margin: '2px',
          cursor: 'pointer',
        }"
      ></topology-source-icon>
    </div>
    <div
      class="service-node-name text-overflow-multiline"
      style="
        width: 80px;
        font-size: 11px;
        color: #575757;
        text-align: center;
        margin: 1px 0 0 -18px;
        cursor: default;
        line-height: 1.3em;
      "
    >
      {{ name }}
    </div>
  </a-tooltip>
</template>

<script>
import TopologySourceIcon from '@/components/icon/TopologySourceIcon'
import { getOS, getOSAlertList } from '@/api/os'
import { getNetworkDevice, getNetworkDeviceAlertList } from '@/api/network-device'
import { getStorage, getStorageAlertList } from '@/api/storage'
import { getServer, getServerAlertList } from '@/api/server'
import { getMiddleware, getMiddlewareAlertList } from '@/api/middleware'
import { getDatabase, getDatabaseAlertList } from '@/api/database'
import { getApplication, getApplicationAlertList } from '@/api/application'
import { getDomain, getDomainAlertList } from '@/api/domain'
import { getSite, getSiteAlertList } from '@/api/site'
import { getSSLCertificate, getSSLCertificateAlertList } from '@/api/ssl-certificate'

export default {
  name: 'SourceNode',
  components: {
    TopologySourceIcon
  },
  inject: ['getNode'],
  data () {
    return {
      name: '',
      sourceId: '',
      sourceType: '',
      status: '',
      baseStyle: {
        padding: '8px',
        height: '48px',
        width: '48px',
        borderRadius: '50%',
        border: '1px solid'
      },
      normalStyle: {
        background: '#00a95c30',
        borderColor: '#00a95ca1'
      },
      alertStyle: {
        background: '#ffa04030',
        borderColor: '#ffa040a1'
      },
      abnormalStyle: {
        background: '#f8593e30',
        borderColor: '#f8593ea1'
      },
      unknownStyle: {
        background: '#d9d9d930',
        borderColor: '#d9d9d9a1'
      },
      params: {
        page: 1,
        page_size: 20,
        recovered: false
      },
      hasHighAlert: false
    }
  },
  computed: {
    statusStyle () {
      if (this.status === 'normal') return [this.baseStyle, this.normalStyle]
      if (this.status === 'alert' && !this.hasHighAlert) return [this.baseStyle, this.alertStyle]
      if (this.status === 'abnormal' || this.hasHighAlert) return [this.baseStyle, this.abnormalStyle]
      return [this.baseStyle, this.unknownStyle]
    },
    getFunc () {
      switch (this.sourceType) {
        case 'network_device':
          return {
            getDetailFunc: getNetworkDevice,
            getAlertFunc: getNetworkDeviceAlertList
          }
        case 'storage':
          return {
            getDetailFunc: getStorage,
            getAlertFunc: getStorageAlertList
          }
        case 'server':
          return {
            getDetailFunc: getServer,
            getAlertFunc: getServerAlertList
          }
        case 'os':
          return {
            getDetailFunc: getOS,
            getAlertFunc: getOSAlertList
          }
        case 'middleware':
          return {
            getDetailFunc: getMiddleware,
            getAlertFunc: getMiddlewareAlertList
          }
        case 'database':
          return {
            getDetailFunc: getDatabase,
            getAlertFunc: getDatabaseAlertList
          }
        case 'site':
          return {
            getDetailFunc: getSite,
            getAlertFunc: getSiteAlertList
          }
        case 'domain':
          return {
            getDetailFunc: getDomain,
            getAlertFunc: getDomainAlertList
          }
        case 'ssl_certificate':
          return {
            getDetailFunc: getSSLCertificate,
            getAlertFunc: getSSLCertificateAlertList
          }
        case 'application':
          return {
            getDetailFunc: getApplication,
            getAlertFunc: getApplicationAlertList
          }
        default:
          return {
            getDetailFunc: getNetworkDevice,
            getAlertFunc: getNetworkDevice
          }
      }
    }
  },
  mounted () {
    const data = this.getNode().getData()
    this.name = data.name
    this.sourceId = data.sourceId
    this.sourceType = data.sourceType
    if (this.sourceId) this.getStatus()
  },
  methods: {
    getStatus () {
      this.getFunc.getDetailFunc(this.sourceId).then(res => {
        this.status = res.data.status || 'unknown'
        const data = this.getNode().getData()
        data.status = this.status
        if (this.status === 'alert') {
          this.params.source_id = this.sourceId
          this.getFunc.getAlertFunc(this.params).then(res => {
            const data = res.data
            if (data.data.length && data.data.some(item => item.severity === 'disaster' || item.severity === 'high')) this.hasHighAlert = true
            else this.hasHighAlert = false
          })
        }
      })
    }
  }
}
</script>
<style lang="less">
.status-container-outer {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1;
  .status-container-inner {
    border-radius: 50%;
    position: absolute;
    opacity: 0.3;
    animation: status 0.4s infinite alternate;
  }
}
</style>
