const settingRoutes = [
  {
    path: 'setting/event',
    name: 'EventManagement',
    component: () => import('@/layouts/BlankLayout'),
    meta: {
      title: '事件管理',
      icon: 'event'
    },
    children: [
      {
        path: 'source',
        name: 'EventSource',
        meta: {
          title: '事件源',
          requireApiPermission: ['base.event_source.view']
        },
        component: () => import('@/views/event/EventSource')
      },
      {
        path: 'notification',
        name: 'EventNotificationStrategy',
        meta: {
          title: '通知策略'
        },
        component: () => import('@/views/event/EventNotificationStrategy')
      }
    ]
  },
  // {
  //   path: 'setting/log',
  //   name: 'LogManagement',
  //   component: () => import('@/layouts/BlankLayout'),
  //   meta: {
  //     title: '日志管理',
  //     icon: 'log'
  //   },
  //   children: [
  //     {
  //       path: 'trigger',
  //       name: 'LogTrigger',
  //       meta: {
  //         title: '告警规则',
  //         requireApiPermission: ['base.log_trigger.view']
  //       },
  //       component: () => import('@/views/log-center/LogTrigger')
  //     }
  //   ]
  // },
  {
    path: 'setting/personnel',
    name: 'Personnel',
    component: () => import('@/layouts/BlankLayout'),
    meta: {
      title: '用户/组织管理',
      icon: 'personnel'
    },
    children: [
      {
        path: 'user',
        name: 'User',
        meta: {
          title: '用户管理',
          requireApiPermission: ['base.user.view']
        },
        component: () => import('@/views/User/index.vue')
      },
      {
        path: 'role',
        name: 'Role',
        meta: {
          title: '角色管理',
          requireApiPermission: ['base.role.view']
        },
        component: () => import('@/views/Role/index.vue')
      },
      {
        path: 'organization',
        name: 'Organization',
        meta: {
          title: '组织管理',
          requireApiPermission: ['base.organization.view']
        },
        component: () => import('@/views/Organization/index.vue')
      },
      {
        path: 'contact',
        name: 'ContactList',
        meta: {
          title: '联系人',
          requireApiPermission: ['base.contact.view']
        },
        component: () => import('@/views/Contact')
      }
    ]
  },
  {
    path: 'setting/alert-strategy',
    name: 'AlertStrategy',
    component: () => import('@/layouts/BlankLayout'),
    meta: {
      title: '告警策略管理',
      icon: 'alert'
    },
    children: [
      {
        path: 'notification',
        name: 'AlertNotificationStrategy',
        meta: {
          title: '通知策略',
          requireApiPermission: ['base.alert_notification_strategy.view']
        },
        component: () => import('@/views/AlertNotificationStrategy')
      },
      {
        path: 'block',
        name: 'AlertBlockStrategy',
        meta: {
          title: '屏蔽策略',
          requireApiPermission: ['base.alert_block_strategy.view']
        },
        component: () => import('@/views/AlertBlockStrategy')
      },
      {
        path: 'automation',
        name: 'AlertAutomationStrategy',
        meta: {
          title: '自动化策略',
          requireApiPermission: ['ops.alert_automation_strategy.view']
        },
        component: () => import('@/views/AlertAutomationStrategy')
      }
    ]
  },
  {
    path: 'setting/devops',
    name: 'DevopsSetting',
    meta: {
      title: '运维管理',
      icon: 'devops',
      requireModule: 'ops'
    },
    component: () => import('@/layouts/BlankLayout'),
    children: [
      {
        path: 'script',
        name: 'ScriptList',
        meta: {
          title: '脚本仓库管理',
          requireApiPermission: ['ops.script.view']
        },
        component: () => import('@/views/ScriptList')
      },
      {
        path: 'patrol-item',
        name: 'PatrolStrategyList',
        meta: {
          title: '巡检模板管理',
          requireApiPermission: ['ops.patrol_item.view']
        },
        component: () => import('@/views/patrol-strategy/PatrolItemList')
      },
      {
        path: 'monitor-template',
        name: 'MonitorTemplate',
        meta: {
          title: '监控模板管理'
        },
        component: () => import('@/views/MonitorTemplate')
      },
      {
        path: 'monitor-proxy',
        name: 'MonitorProxy',
        meta: {
          title: '代理配置管理'
        },
        component: () => import('@/views/MonitorProxy')
      }
    ]
  },
  {
    path: 'setting/security',
    name: 'SecuritySetting',
    meta: {
      title: '安全管理',
      icon: 'security'
    },
    component: () => import('@/layouts/BlankLayout'),
    children: [
      {
        path: 'login',
        name: 'LoginLog',
        meta: {
          title: '登录日志',
          requireApiPermission: ['base.login_log.view']
        },
        component: () => import('@/views/LoginLog')
      },
      {
        path: 'operation',
        name: 'OperationLog',
        meta: {
          title: '操作日志',
          requireApiPermission: ['base.operation_log.view']
        },
        component: () => import('@/views/OperationLog')
      }
    ]
  },
  {
    path: 'setting/system',
    name: 'System',
    meta: {
      title: '系统管理',
      icon: 'system',
      requireApiPermission: ['base.setting.view']
    },
    component: () => import('@/views/System')
  }
]

export default settingRoutes
