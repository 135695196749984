<template>
  <div
    style="
      width: 32px;
      height: 32px;
      background-color: #f0f2f5;
      text-align: center;
      border-radius: 50%;
    "
  >
    <a-icon
      theme="filled"
      :type="type"
      @click="e => $emit('click', e)"
      style="font-size: 20px; color: #5e98e3; margin: 6px 0; cursor: pointer"
    ></a-icon>
  </div>
</template>

<script>
export default {
  name: 'CollapseButton',
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    type () {
      return this.collapsed ? 'right-circle' : 'left-circle'
    }
  }
}
</script>
