import Vue from 'vue'
import Vuex from 'vuex'

import getters from '@/store/getters'

import permission from './modules/permission'
import system from './modules/system'
import user from './modules/user'
import cancelRequest from './modules/cancel-request'
import license from './modules/license'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { permission, system, user, cancelRequest, license },
  getters
})
