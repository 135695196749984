import Vue from 'vue'
import VueDraggableResizable from 'vue-draggable-resizable'

import App from '@/App'
import i18n from '@/locale'
import router from '@/router'
import store from '@/store'

import '@antv/x6-vue-shape'
import {
  scrollBoard,
  scrollRankingBoard,
  borderBox13
} from '@jiaminghi/data-view'
import '@/libs/antd'
import echarts from '@/libs/echarts'
import { initTopology } from '@/utils/topology'

Vue.config.productionTip = false
Vue.use(scrollBoard)
Vue.use(borderBox13)
Vue.use(scrollRankingBoard)
Vue.component('vue-draggable-resizable', VueDraggableResizable)

initTopology()

Vue.prototype.$echarts = echarts

Vue.directive('permission', {
  inserted (el, binding) {
    const currentValue = binding.value.action
    const effect = binding.value.effect
    const apiPermissions = store.getters.apiPermissions
    if (apiPermissions.findIndex(item => item.name === '全部接口') === -1) {
      if (apiPermissions.findIndex(item => item.key === currentValue) === -1) {
        if (effect === 'disabled') {
          el.disabled = true
        // el.classList
        } else {
          el.parentNode.removeChild(el)
        }
      }
    }
  }
})

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
