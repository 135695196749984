<template>
    <a-result
      status="403"
      title="403"
      sub-title="抱歉，您无权限访问"
      style="padding-top: 128px"
    >
      <!-- <a-button slot="extra" type="primary" style="z-index: 1000">
        <router-link :to="{ name: 'Login' }">重新登录</router-link>
      </a-button> -->
    </a-result>
</template>

<script>
export default {
  name: 'Error403'
}
</script>
