import NProgress from 'nprogress'
import Vue from 'vue'
import VueRouter from 'vue-router'
import storage from 'store'

import config from '@/config'
import store from '@/store'
import 'nprogress/nprogress.css'

import AuthLayout from '@/layouts/AuthLayout'

NProgress.configure({ showSpinner: false })

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/auth',
      name: 'Auth',
      component: AuthLayout,
      redirect: '/auth/login',
      children: [
        {
          path: 'login',
          name: 'Login',
          component: () => import('@/views/Login')
        },
        {
          path: 'sso',
          name: 'SSO',
          component: () => import('@/views/SSOPage')
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  store.state.cancelRequest.promiseArr.forEach((e, index) => {
    e.cancel('取消请求')
  })
  store.commit('cancelRequest/CLEAR_PROMISE_ARR')

  NProgress.start()
  document.title = config.title
  if (store.getters.publicKey === '') {
    store.dispatch('GetSystemSetting')
  }
  if (to.name === 'SSO') {
    next()
  } else {
    if (storage.get('ACCESS_TOKEN')) {
      if (store.getters.nickname === '') {
        store.dispatch('GetProfile').then(() => {
          store.dispatch('GetSystemLicense').then((modules) => {
            store.dispatch('GenerateRoutes', { modules }).then(() => {
              store.getters.routes.forEach(route => {
                router.addRoute(route)
              })
            })
          })
        })
      }
      if (to.name === 'Login') {
        next({ name: 'Index' })
      } else {
        const redirect = decodeURIComponent(from.query.redirect || to.path)
        if (to.path === redirect) {
          next()
        } else {
          next({ path: redirect })
        }
      }
    } else {
      if (to.name === 'Login') {
        next()
      } else {
        next({ name: 'Login', query: { redirect: to.fullPath } })
      }
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
