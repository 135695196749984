import MainLayout from '@/layouts/MainLayout'
import store from '@/store'
import { menuRoutes } from '@/router/routes'
import Error403 from '@/views/error/Error403.vue'
import { DEFAULT_MENU_MODULES } from '@/utils/const'

function filterModule (routes, modules) {
  return routes.filter(route => {
    const requireModule = route.meta ? route.meta.requireModule : undefined
    if (requireModule !== undefined) {
      if (modules.findIndex(m => m === requireModule) === -1) return false
      else return true
    } else {
      if (route.children && route.children.length !== 0) {
        route.children = filterModule(route.children, modules)
      }
      return true
    }
  })
}

function filterPermission (routes, permissionRoutes) {
  return routes.filter(route => {
    const index = permissionRoutes.findIndex(pRoute => pRoute.key === route.name)
    if (index !== -1) {
      if (route.children && route.children.length !== 0) {
        route.children = filterPermission(route.children, permissionRoutes)
      }
      return true
    }
    return false
  })
}

function filterNoViewPermission (routes, apiPermissions) {
  return routes.filter(route => {
    if (route.children && route.children.length) {
      route.children = filterNoViewPermission(route.children, apiPermissions)
      return true
    } else {
      if (route.meta && route.meta.requireApiPermission && route.meta.requireApiPermission.length) {
        const flag = route.meta.requireApiPermission.some(item => apiPermissions.findIndex(p => p.key === item) === -1)
        if (flag) {
          route.component = Error403
        }
      }
      return true
    }
  })
}

function setRedirectPath (routes) {
  return routes.map(route => {
    if (route.children && route.children.length) {
      route.redirect = `${route.path}/${route.children[0].path}`
    }
    return route
  })
}

export default {
  state: {
    routes: [],
    menuRoutes: [],
    headerMenuRoutes: []
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.routes = routes
    },
    SET_MENU_ROUTES: (state, menuRoutes) => {
      state.menuRoutes = menuRoutes
    },
    SET_HEADER_MENU_ROUTES: (state, headerMenuRoutes) => {
      state.headerMenuRoutes = headerMenuRoutes
    }
  },
  actions: {
    GenerateRoutes ({ commit }, data) {
      const modules = data.modules
      return new Promise(resolve => {
        let _menuRoutes
        // 1.模块过滤
        if (modules && modules.length) {
          _menuRoutes = filterModule(menuRoutes, modules)
        } else _menuRoutes = []

        // 2.菜单权限过滤
        const pagePermissions = store.getters.pagePermissions
        if (pagePermissions.findIndex(item => item.name === '全部页面') === -1) {
          _menuRoutes = filterPermission(_menuRoutes, pagePermissions)
        }

        // 3.api权限过滤（查看权限的页面组件改为403）
        const apiPermissions = store.getters.apiPermissions
        if (apiPermissions.findIndex(item => item.name === '全部接口') === -1) {
          _menuRoutes = filterNoViewPermission(_menuRoutes, apiPermissions)
        }

        // 设置过滤后路由的重定向路径
        _menuRoutes = setRedirectPath(_menuRoutes)

        // 4.隐藏无路由的头部路由
        let headerMenus = DEFAULT_MENU_MODULES
        DEFAULT_MENU_MODULES.forEach(item => {
          const routes = _menuRoutes.filter(m => m.path.split('/')[0] === item.key)
          if (!routes.length) {
            headerMenus = headerMenus.filter(v => v.key !== item.key)
          }
        })
        const children = [
          ..._menuRoutes,
          {
            path: 'profile',
            name: 'UserProfile',
            meta: {
              title: '个人中心',
              hidden: true
            },
            component: () => import('@/views/UserProfile')
          }
        ]

        commit('SET_ROUTES', [
          {
            path: '/',
            name: 'Index',
            meta: { title: '首页' },
            redirect: children[0].path,
            component: MainLayout,
            children
          },
          {
            path: '/5xx',
            name: '5xx',
            component: () => import('@/views/error/Error5xx.vue')
          },
          {
            path: '*',
            name: '404',
            component: () => import('@/views/error/Error404.vue')
          }
        ])
        commit('SET_MENU_ROUTES', _menuRoutes)
        commit('SET_HEADER_MENU_ROUTES', headerMenus)
        resolve()
      })
    }
  }
}
