var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',{staticClass:"main-layout"},[_c('a-layout-header',{style:({
      background: '#fff',
      height: '60px',
      lineHeight: '60px',
      fontWeight: 600,
      padding: '0 24px 0 0px',
      borderBottom: '1px solid #e5e5e5',
      zIndex: 999,
      position: 'fixed',
      width: '100vw',
      boxShadow: '0 1px 10px rgba(0, 0, 0, 0.15)'
    })},[_c('div',{staticStyle:{"width":"200px","text-align":"center","float":"left"}},[_c('img',{staticStyle:{"max-width":"200px","max-height":"60px"},attrs:{"src":require("@/assets/images/logo.png"),"alt":"Logo"}})]),_c('a-menu',{staticStyle:{"width":"calc(100vw - 436px)","float":"left","border":"none","margin-left":"24px","margin-top":"-1px"},attrs:{"selected-keys":_vm.topMenuSelect,"mode":"horizontal"},on:{"select":_vm.handleTopMenuSelect}},_vm._l((_vm.headerMenuRoutes),function(item){return _c('a-menu-item',{key:item.key},[_c('header-menu-icon',{staticStyle:{"font-size":"16px"},attrs:{"icon":item.headerIcon}}),_c('span',[_vm._v(_vm._s(item.name))])],1)}),1),_c('div',{staticClass:"top-menu-right",staticStyle:{"width":"160px","float":"right","text-align":"right"}},[_c('dropdown-menu')],1)],1),_c('a-layout',{staticStyle:{"margin-top":"60px","min-height":"calc(100vh - 130px)"}},[(_vm.topMenuSelect[0] !== 'profile')?[_c('collapse-button',{style:({
          position: 'fixed',
          bottom: '64px',
          left: _vm.collapsed ? '62px' : '180px',
          zIndex: 1000
        }),attrs:{"collapsed":_vm.collapsed},on:{"click":function($event){_vm.collapsed = !_vm.collapsed}}}),_c('a-layout-sider',{staticStyle:{"z-index":"998","background":"#fff","position":"fixed","height":"calc(100vh - 60px)","padding-top":"4px","box-shadow":"0 0 4px rgba(0, 0, 0, 0.1)"},attrs:{"breakpoint":"md","collapsible":true,"trigger":null},model:{value:(_vm.collapsed),callback:function ($$v) {_vm.collapsed=$$v},expression:"collapsed"}},[_c('a-menu',{attrs:{"mode":"inline","open-keys":_vm.openKeys,"selected-keys":_vm.selectedKeys,"theme":"light"},on:{"update:openKeys":function($event){_vm.openKeys=$event},"update:open-keys":function($event){_vm.openKeys=$event},"select":({ key }) => _vm.$router.push({ name: key })}},[_vm._l((_vm.menuItems),function(menuItem){return [(menuItem.children)?[_c('a-sub-menu',{key:menuItem.name},[_c('template',{slot:"title"},[(menuItem.meta.icon)?_c('slider-menu-icon',{staticStyle:{"font-size":"16px"},attrs:{"icon":menuItem.meta.icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(menuItem.meta.title))])],1),_vm._l((menuItem.children),function(child){return [(child.meta.hidden !== true)?_c('a-menu-item',{key:child.name},[_vm._v(" "+_vm._s(child.meta.title)+" ")]):_vm._e()]})],2)]:[(menuItem.meta.hidden !== true)?_c('a-menu-item',{key:menuItem.name},[(menuItem.meta.icon)?_c('slider-menu-icon',{staticStyle:{"font-size":"16px"},attrs:{"icon":menuItem.meta.icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(menuItem.meta.title))])],1):_vm._e()]]})],2)],1),_c('a-layout-content',{style:({
          padding: '16px 16px 0 16px',
          marginLeft: _vm.collapsed ? '80px' : '200px'
        })},[_c('router-view',{staticStyle:{"z-index":"3","position":"relative"}}),_c('a-back-top')],1)]:[_c('a-layout-content',{style:({
          padding: '16px 16px 0 16px'
        })},[_c('router-view',{staticStyle:{"z-index":"3","position":"relative"}}),_c('a-back-top')],1)]],2),_c('layout-footer',{style:({ marginLeft: _vm.collapsed ? '80px' : '200px' })})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }