import qs from 'qs'
import request from '@/utils/request'

const urlPrefix = '/network-devices'

export function getNetworkDevice (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateNetworkDevice (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteNetworkDevice (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getNetworkDeviceFanList (id, params) {
  return request.get(`${urlPrefix}/${id}/fans`, { params })
}

export function getNetworkDevicePSUList (id, params) {
  return request.get(`${urlPrefix}/${id}/psus`, { params })
}

export function getNetworkDeviceTemperatureList (id, params) {
  return request.get(`${urlPrefix}/${id}/temperatures`, { params })
}

export function getNetworkDeviceNetworkInterfaceList (id, params) {
  return request.get(`${urlPrefix}/${id}/network-interfaces`, { params })
}

export function getNetworkDeviceMonitorStatusList (id) {
  return request.get(`${urlPrefix}/${id}/monitor/statuses`)
}

export function getNetworkDeviceHealthStatusList (id) {
  return request.get(`${urlPrefix}/${id}/health/statuses`)
}

export function getNetworkDeviceMonitorItemList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/items`, { params })
}

export function getNetworkDeviceMonitorHistory (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/history`, { params })
}

export function getNetworkDeviceMonitorTriggerList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/triggers`, { params })
}

export function updateNetworkDeviceMonitorTrigger (id, data) {
  return request.post(`${urlPrefix}/${id}/monitor/triggers`, data)
}

export function getNetworkDeviceList (params) {
  return request.get(urlPrefix, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    }
  })
}

export function createNetworkDevice (data) {
  return request.post(urlPrefix, data)
}

export function getNetworkDeviceTemplateList (params) {
  return request.get(`${urlPrefix}/monitor/templates`, { params })
}

export function getNetworkDeviceCount (params) {
  return request.get(`${urlPrefix}/count`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    }
  })
}

export function getNetworkDeviceAlertCount (params) {
  return request.get(`${urlPrefix}/alerts/count`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    }
  })
}

export function getNetworkDeviceTop (params) {
  return request.get(`${urlPrefix}/top`, { params })
}

export function getAbnormalNetworkDeviceList (params) {
  return request.get(`${urlPrefix}/abnormal`, { params })
}

export function getPerformanceNetworkDeviceList (params) {
  return request.get(`${urlPrefix}/performance`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    }
  })
}

export function getNetworkDeviceAlertList (params) {
  return request.get(`${urlPrefix}/alerts`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    }
  })
}

export function syncNetworkDevice (data) {
  return request.post(`${urlPrefix}/sync`, data)
}

export function scanNetworkDevice (data) {
  return request.post(`${urlPrefix}/scan`, data)
}

export function testNetworkDevice (data) {
  return request.post(`${urlPrefix}/test`, data)
}

export function exportNetworkDevice (params) {
  return request.get(`${urlPrefix}/export`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    },
    responseType: 'blob'
  })
}

export function getNetworkDeviceImportTemplate () {
  return request.get(`${urlPrefix}/import/template`, { responseType: 'blob' })
}

export function importNetworkDevice (data) {
  return request.post(`${urlPrefix}/import`, data)
}

export function getNetworkDeviceGroup (id) {
  return request.get(`${urlPrefix}/groups/${id}`)
}

export function updateNetworkDeviceGroup (id, data) {
  return request.put(`${urlPrefix}/groups/${id}`, data)
}

export function deleteNetworkDeviceGroup (id) {
  return request.delete(`${urlPrefix}/groups/${id}`)
}

export function getNetworkDeviceGroupList () {
  return request.get(`${urlPrefix}/groups/tree`)
}

export function createNetworkDeviceGroup (data) {
  return request.post(`${urlPrefix}/groups`, data)
}

export function getNetworkDeviceGroupCount (params) {
  return request.get(`${urlPrefix}/groups/count`, { params })
}

export function getNetworkDeviceHealthScore (id) {
  return request.get(`${urlPrefix}/${id}/health/score`)
}
