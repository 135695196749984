const logRoutes = [
  {
    path: 'log/middleware',
    name: 'MiddlewareLog',
    component: () => import('@/layouts/BlankLayout'),
    meta: {
      title: '中间件日志',
      icon: 'middleware',
      requireApiPermission: ['base.middleware_log.view']
    },
    children: [
      {
        path: 'nginx',
        name: 'NginxLog',
        meta: {
          title: 'Nginx',
          requireApiPermission: ['base.middleware_log.view']
        },
        component: () => import('@/views/log-center/NginxLog')
      }
    ]
  }
]
export default logRoutes
