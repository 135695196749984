import qs from 'qs'
import request from '@/utils/request'

const urlPrefix = '/oses'

export function getOS (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateOS (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteOS (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getOSFilesystemList (id) {
  return request.get(`${urlPrefix}/${id}/filesystems`)
}

export function getOSNetworkInterfaceList (id) {
  return request.get(`${urlPrefix}/${id}/network-interfaces`)
}

export function getOSProcessList (id) {
  return request.get(`${urlPrefix}/${id}/processes`)
}

export function getOSSoftwareList (id) {
  return request.get(`${urlPrefix}/${id}/softwares`)
}

export function createOSSoftware (id, data) {
  return request.post(`${urlPrefix}/${id}/softwares`, data)
}

export function updateOSSoftware (id, data) {
  return request.put(`${urlPrefix}/softwares/${id}`, data)
}

export function deleteOSSoftware (id) {
  return request.delete(`${urlPrefix}/softwares/${id}`)
}

export function getOSPortList (id) {
  return request.get(`${urlPrefix}/${id}/ports`)
}

export function getOSMonitorPortList (id) {
  return request.get(`${urlPrefix}/${id}/monitor-ports`)
}

export function deleteOSMonitorPort (id) {
  return request.delete(`${urlPrefix}/monitor-ports/${id}`)
}

export function createOSMonitorPort (id, data) {
  return request.post(`${urlPrefix}/${id}/monitor-ports`, data)
}

export function updateOSMonitorPort (id, data) {
  return request.put(`${urlPrefix}/monitor-ports/${id}`, data)
}

export function getOSMonitorStatusList (id) {
  return request.get(`${urlPrefix}/${id}/monitor/statuses`)
}

export function getOSHealthStatusList (id) {
  return request.get(`${urlPrefix}/${id}/health/statuses`)
}

export function getOSMonitorItemList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/items`, { params })
}

export function getOSMonitorHistory (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/history`, { params })
}

export function getOSMonitorTriggerList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/triggers`, { params })
}

export function updateOSMonitorTrigger (id, data) {
  return request.post(`${urlPrefix}/${id}/monitor/triggers`, data)
}

export function deleteOSMonitorProcesses (id) {
  return request.delete(`${urlPrefix}/monitor-processes/${id}`)
}

export function createOSMonitorProcesses (id, data) {
  return request.post(`${urlPrefix}/${id}/monitor-processes`, data)
}

export function getOSMonitorProcessesList (id) {
  return request.get(`${urlPrefix}/${id}/monitor-processes`)
}

export function getPerformanceOSList (params) {
  return request.get(`${urlPrefix}/performance`, { params })
}

export function getOSList (params) {
  return request.get(urlPrefix, { params })
}

export function createOS (data) {
  return request.post(urlPrefix, data)
}

export function getOSCount (params) {
  return request.get(`${urlPrefix}/count`, { params })
}

export function getOSTop (params) {
  return request.get(`${urlPrefix}/top`, { params })
}

export function syncOS (data) {
  return request.post(`${urlPrefix}/sync`, data)
}

export function exportOS (params) {
  return request.get(`${urlPrefix}/export`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    },
    responseType: 'blob'
  })
}

export function getOSGroup (id) {
  return request.get(`${urlPrefix}/groups/${id}`)
}

export function updateOSGroup (id, data) {
  return request.put(`${urlPrefix}/groups/${id}`, data)
}

export function deleteOSGroup (id) {
  return request.delete(`${urlPrefix}/groups/${id}`)
}

export function getOSGroupList () {
  return request.get(`${urlPrefix}/groups/tree`)
}

export function createOSGroup (data) {
  return request.post(`${urlPrefix}/groups`, data)
}

export function getOSHealthScore (id) {
  return request.get(`${urlPrefix}/${id}/health/score`)
}

export function getOSAlertList (params) {
  return request.get(`${urlPrefix}/alerts`, { params })
}

export function getOSAlertCount (params) {
  return request.get(`${urlPrefix}/alerts/count`, { params })
}
