<template>
  <a-layout-footer
    style="text-align: center; user-select: none; background: inherit"
  >
    {{ new Date().getFullYear() }} @ {{ title }}
  </a-layout-footer>
</template>

<script>
import config from '@/config'

export default {
  name: 'LayoutFooter',
  data () {
    return {
      title: config.title
    }
  }
}
</script>
