import request from '@/utils/request'

const urlPrefix = '/applications'

export function getApplication (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateApplication (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteApplication (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getApplicationMonitorStatusList (id) {
  return request.get(`${urlPrefix}/${id}/monitor/statuses`)
}

export function getApplicationMonitorHistory (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/history`, { params })
}

export function getApplicationMonitorItemList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/items`, { params })
}

export function getApplicationMonitorTriggerList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/triggers`, { params })
}

export function updateApplicationMonitorTrigger (id, data) {
  return request.post(`${urlPrefix}/${id}/monitor/triggers`, data)
}

export function getApplicationMiddlewareList (id) {
  return request.delete(`${urlPrefix}/${id}/middlewares`)
}

export function getApplicationDatabaseList (id) {
  return request.delete(`${urlPrefix}/${id}/databases`)
}

export function getApplicationList (params) {
  return request.get(urlPrefix, { params })
}

export function createApplication (data) {
  return request.post(urlPrefix, data)
}

export function syncApplication (data) {
  return request.post(`${urlPrefix}/sync`, data)
}

export function getApplicationGroup (id) {
  return request.get(`${urlPrefix}/groups/${id}`)
}

export function updateApplicationGroup (id, data) {
  return request.put(`${urlPrefix}/groups/${id}`, data)
}

export function deleteApplicationGroup (id) {
  return request.delete(`${urlPrefix}/groups/${id}`)
}

export function getApplicationGroupList () {
  return request.get(`${urlPrefix}/groups/tree`)
}

export function createApplicationGroup (data) {
  return request.post(`${urlPrefix}/groups`, data)
}

export function getApplicationAlertList (params) {
  return request.get(`${urlPrefix}/alerts`, { params })
}
