import request from '@/utils/request'

const urlPrefix = '/users'

export function login (data) {
  return request.post(`${urlPrefix}/login`, data)
}

export function loginSSO (data) {
  return request.post(`${urlPrefix}/login/sso`, data)
}

export function getUser (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateUser (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteUser (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getUserList (params) {
  return request.get(urlPrefix, { params })
}

export function createUser (data) {
  return request.post(urlPrefix, data)
}

export function getUserProfile () {
  return request.get(`${urlPrefix}/profile`)
}

export function updateUserProfile (data) {
  return request.put(`${urlPrefix}/profile`, data)
}

export function updatePassword (data) {
  return request.post(`${urlPrefix}/password`, data)
}

export function getCaptcha () {
  return request.get('/captcha')
}

export function getApiPermission () {
  return request.get('/api-permissions')
}

export function getPagePermission () {
  return request.get('/page-permissions')
}
