const monitoringRoutes = [
  {
    path: 'monitoring/hardware',
    name: 'Hardware',
    component: () => import('@/layouts/BlankLayout'),
    meta: {
      title: '硬件资源',
      icon: 'hardware'
    },
    children: [
      {
        path: 'switchboard',
        name: 'Switchboard',
        meta: {
          title: '交换机',
          requireApiPermission: ['base.network_device.view']
        },
        component: () => import('@/views/Switchboard')
      },
      {
        path: 'ac-ap',
        name: 'ACAPList',
        meta: {
          title: 'AC-AP',
          requireApiPermission: ['base.network_device.view']
        },
        component: () => import('@/views/ac-ap/ACAPList')
      },
      {
        path: 'ac-ap/:id/detail',
        name: 'ACAPDetail',
        meta: {
          title: 'AC-AP 详情',
          hidden: true,
          requireApiPermission: ['base.network_device.view']
        },
        component: () => import('@/views/ac-ap/ACAPDetail')
      },
      {
        path: 'security',
        name: 'Security',
        meta: {
          title: '安全设备',
          requireApiPermission: ['base.network_device.view']
        },
        component: () => import('@/views/Security')
      },
      {
        path: 'server',
        name: 'Server',
        meta: {
          title: '物理机',
          requireApiPermission: ['base.server.view']
        },
        component: () => import('@/views/Server')
      },
      {
        path: 'storage',
        name: 'Storage',
        meta: {
          title: '存储',
          requireApiPermission: ['base.storage.view']
        },
        component: () => import('@/views/Storage')
      },
      {
        path: 'terminal',
        name: 'Terminal',
        meta: {
          title: '终端设备',
          requireApiPermission: ['base.terminal_device.view']
        },
        component: () => import('@/views/Terminal')
      }
    ]
  },
  {
    path: 'monitoring/software',
    name: 'Software',
    component: () => import('@/layouts/BlankLayout'),
    meta: {
      title: '软件资源',
      icon: 'software'
    },
    children: [
      {
        path: 'os',
        name: 'OS',
        meta: {
          title: '操作系统',
          requireApiPermission: ['base.os.view']
        },
        component: () => import('@/views/OS')
      },
      {
        path: 'middleware',
        name: 'Middleware',
        meta: {
          title: '中间件',
          requireApiPermission: ['base.middleware.view']
        },
        component: () => import('@/views/Middleware')
      },
      {
        path: 'database',
        name: 'Database',
        meta: {
          title: '数据库',
          requireApiPermission: ['base.database.view']
        },
        component: () => import('@/views/Database')
      }
    ]
  },
  {
    path: 'monitoring/hypervisor',
    name: 'HypervisorDashboardList',
    component: () => import('@/views/hypervisor-dashboard/HypervisorDashboardList'),
    meta: {
      title: '虚拟化资源',
      icon: 'hypervisor',
      requireApiPermission: ['base.hypervisor.view']
    }
  },
  {
    path: 'monitoring/hypervisor/:id/detail',
    name: 'HypervisorDashboardDetail',
    meta: {
      title: '虚拟化详情',
      hidden: true,
      requireApiPermission: ['base.hypervisor.view']
    },
    component: () => import('@/views/hypervisor-dashboard/HypervisorDashboardDetail')
  },
  {
    path: 'monitoring/containerization',
    name: 'Containerization',
    component: () => import('@/layouts/BlankLayout'),
    meta: {
      title: '容器化资源',
      icon: 'container'
    },
    children: [
      {
        path: 'docker',
        name: 'Docker',
        meta: {
          title: 'Docker',
          requireApiPermission: ['base.containerization.view']
        },
        component: () => import('@/views/Docker')
      },
      {
        path: 'kubernetes',
        name: 'Kubernetes',
        meta: {
          title: 'Kubernetes',
          requireApiPermission: ['base.containerization.view']
        },
        component: () => import('@/views/Kubernetes')
      }
    ]
  },
  {
    path: 'monitoring/service',
    name: 'Service',
    component: () => import('@/layouts/BlankLayout'),
    meta: {
      title: '业务资源',
      icon: 'business',
      requireModule: 'service'
    },
    children: [
      {
        path: 'application',
        name: 'Application',
        meta: {
          title: '应用',
          requireApiPermission: ['service.application.view']
        },
        component: () => import('@/views/Application')
      },
      {
        path: 'site',
        name: 'Site',
        meta: {
          title: '站点',
          requireApiPermission: ['service.site.view']
        },
        component: () => import('@/views/Site')
      },
      {
        path: 'domain',
        name: 'Domain',
        meta: {
          title: '域名',
          requireApiPermission: ['service.domain.view']
        },
        component: () => import('@/views/Domain')
      },
      {
        path: 'ssl-certificate',
        name: 'SSLCertificate',
        meta: {
          title: 'SSL 证书',
          requireApiPermission: ['service.ssl_certificate.view']
        },
        component: () => import('@/views/SSLCertificate')
      }
    ]
  },
  {
    path: 'monitoring/network',
    name: 'NetworkMonitoring',
    component: () => import('@/layouts/BlankLayout'),
    meta: {
      title: '网络资源',
      icon: 'network'
    },
    children: [
      {
        path: 'dedicated-line',
        name: 'DedicatedLine',
        meta: {
          title: '专线监控',
          requireApiPermission: ['base.dedicated_line.view']
        },
        component: () => import('@/views/DedicatedLine')
      },
      {
        path: 'network-topology',
        name: 'NetworkTopologyList',
        meta: {
          title: '拓扑构建',
          requireApiPermission: ['base.network_topology.view']
        },
        component: () => import('@/views/network-topology/NetworkTopologyList')
      },
      {
        path: 'network-topology/:id/detail',
        name: 'NetworkTopologyDetail',
        meta: {
          title: '拓扑详情',
          hidden: true,
          requireApiPermission: ['base.network_topology.view']
        },
        component: () => import('@/views/network-topology/NetworkTopologyDetail')
      },
      {
        path: 'ip-subnet',
        name: 'IPSubnet',
        meta: {
          title: 'IP 地址簿',
          requireApiPermission: ['base.ip_subnet.view']
        },
        component: () => import('@/views/IPSubnet')
      }
    ]
  }
]

export default monitoringRoutes
