import { getSystemSetting } from '@/api/setting'
import { Base64 } from 'js-base64'

export default {
  state: {
    serverAddress: '',
    monitorActiveMode: false,
    publicKey: '',
    opsItsmApi: undefined,
    ssoDirectUrl: undefined,
    skywalkingUrl: undefined
  },
  mutations: {
    SET_SERVER_ADDRESS: (state, serverAddress) => {
      state.serverAddress = serverAddress
    },
    SET_MONITOR_ACTIVE_MODE: (state, monitorActiveMode) => {
      state.monitorActiveMode = monitorActiveMode
    },
    SET_PUBLIC_KEY: (state, publicKey) => {
      state.publicKey = publicKey
    },
    SET_OPS_ITSM_API: (state, opsItsmApi) => {
      state.opsItsmApi = opsItsmApi
    },
    SET_SSO_DIRECT_URL: (state, ssoDirectUrl) => {
      state.ssoDirectUrl = ssoDirectUrl
    },
    SET_SKYWALKING_URL: (state, skywalkingUrl) => {
      state.skywalkingUrl = skywalkingUrl
    }
  },
  actions: {
    GetSystemSetting ({ commit }) {
      return new Promise((resolve, reject) => {
        getSystemSetting()
          .then(res => {
            const data = res.data
            commit('SET_SERVER_ADDRESS', data.server_address)
            commit('SET_MONITOR_ACTIVE_MODE', data.monitor_active_mode)
            commit('SET_PUBLIC_KEY', Base64.decode(data.public_key))
            commit('SET_OPS_ITSM_API', data.ops_itsm_api)
            commit('SET_SSO_DIRECT_URL', data.sso_direct_url)
            commit('SET_SKYWALKING_URL', data.skywalking_url)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    }
  }
}
