import storage from 'store'
import { getRole } from '@/api/role'
import { getUserProfile, login, loginSSO } from '@/api/user'

export default {
  state: {
    accessToken: '',
    nickname: '',
    role: undefined,
    apiPermissions: [],
    pagePermissions: []
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, accessToken) => {
      state.accessToken = accessToken
    },
    SET_NICKNAME: (state, nickname) => {
      state.nickname = nickname
    },
    SET_ROLE: (state, role) => {
      state.role = role
    },
    SET_PAGE_PERMISSIONS: (state, pagePermissions) => {
      state.pagePermissions = pagePermissions
    },
    SET_API_PERMISSIONS: (state, apiPermissions) => {
      state.apiPermissions = apiPermissions
    }
  },
  actions: {
    Login ({ commit }, data) {
      return new Promise((resolve, reject) => {
        login(data)
          .then(res => {
            const accessToken = res.data.access_token
            commit('SET_ACCESS_TOKEN', accessToken)
            storage.set('ACCESS_TOKEN', accessToken, { expires: 86400 * 1000 })
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
    LoginSSO ({ commit }, data) {
      return new Promise((resolve, reject) => {
        loginSSO(data)
          .then(res => {
            const accessToken = res.data.access_token
            commit('SET_ACCESS_TOKEN', accessToken)
            storage.set('ACCESS_TOKEN', accessToken, { expires: 86400 * 1000 })
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
    GetProfile ({ commit }) {
      return new Promise((resolve, reject) => {
        getUserProfile()
          .then(res => {
            const data = res.data
            commit('SET_NICKNAME', data.nickname)
            commit('SET_ROLE', data.role)
            if (data.role) {
              getRole(data.role.id).then(res => {
                commit('SET_API_PERMISSIONS', res.data.api_permissions)
                commit('SET_PAGE_PERMISSIONS', res.data.page_permissions)
                resolve()
              })
            } else {
              commit('SET_API_PERMISSIONS', [])
              commit('SET_PAGE_PERMISSIONS', [])
              resolve()
            }
          })
          .catch(error => reject(error))
      })
    },
    Logout ({ commit }) {
      return new Promise(resolve => {
        commit('SET_ACCESS_TOKEN', '')
        storage.remove('ACCESS_TOKEN')
        commit('SET_NICKNAME', '')
        commit('SET_ROLE', undefined)
        commit('SET_PAGE_PERMISSIONS', [])
        commit('SET_API_PERMISSIONS', [])
        resolve()
      })
    }
  }
}
