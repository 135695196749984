
const displayRoutes = [
  {
    path: 'display/monitoring-display',
    name: 'MonitoringDisplay',
    component: () => import('@/layouts/BlankLayout'),
    meta: {
      title: '监控汇总',
      icon: 'monitor'
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        meta: {
          title: '仪表盘'
        },
        component: () => import('@/views/Dashboard')
      },
      {
        path: 'service-wall',
        name: 'ServiceDashboardList',
        meta: {
          title: '业务墙',
          requireModule: 'service',
          requireApiPermission: ['service.service.view']
        },
        component: () => import('@/views/service-dashboard/ServiceDashboardList')
      },
      {
        path: 'service-wall/:id/detail',
        name: 'ServiceDashboardDetail',
        meta: {
          title: '业务详情',
          hidden: true,
          requireModule: 'service',
          requireApiPermission: ['service.service.view']
        },
        component: () => import('@/views/service-dashboard/ServiceDashboardDetail')
      },
      {
        path: 'service-wall/:id/detail/topology',
        name: 'ServiceDashboardTopologyDetail',
        meta: {
          title: '业务拓扑',
          hidden: true,
          requireModule: 'service',
          requireApiPermission: ['service.service.view']
        },
        component: () => import('@/views/service-dashboard/ServiceDashboardTopologyDetail')
      },
      {
        path: 'service-wall/:id/detail/topology/edit',
        name: 'ServiceDashboardTopologyEdit',
        meta: {
          title: '业务拓扑编辑',
          hidden: true,
          requireModule: 'service',
          requireApiPermission: ['service.service.update']
        },
        component: () => import('@/views/service-dashboard/ServiceDashboardTopologyEdit')
      }
    ]
  },
  {
    path: 'display/event',
    name: 'EventDisplay',
    component: () => import('@/layouts/BlankLayout'),
    meta: {
      title: '事件汇总',
      icon: 'event'
    },
    children: [
      {
        path: 'history',
        name: 'EventHistory',
        meta: {
          title: '历史事件',
          requireApiPermission: ['base.event.view']
        },
        component: () => import('@/views/event/EventHistory.vue')
      }
    ]
  },
  {
    path: 'display/alert',
    name: 'AlertDisplay',
    component: () => import('@/layouts/BlankLayout'),
    meta: {
      title: '告警汇总',
      icon: 'alert'
    },
    children: [
      {
        path: 'history',
        name: 'AlertHistory',
        meta: {
          title: '历史告警',
          requireApiPermission: ['base.alert.view']
        },
        component: () => import('@/views/AlertHistory')
      }
    ]
  },
  {
    path: 'display/visualization',
    name: 'VisualizationDisplay',
    component: () => import('@/layouts/BlankLayout'),
    meta: {
      title: '大屏可视化',
      icon: 'big_screen'
    },
    children: [
      {
        path: 'network',
        name: 'NetworkVisualization',
        meta: {
          title: '网络大屏'
        },
        component: () => import('@/views/NetworkBigScreen')
      },
      {
        path: 'service',
        name: 'ServiceVisualization',
        meta: {
          title: '业务大屏',
          requireModule: 'service'
        },
        component: () => import('@/views/BigScreen')
      }
    ]
  // },
  // {
  //   path: 'display/skywalking',
  //   name: 'Skywalking',
  //   meta: {
  //     title: 'Skywalking',
  //     icon: 'api'
  //   },
  //   component: () => import('@/views/Skywalking')
  }
]

export default displayRoutes
