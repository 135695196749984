const reportRoutes = [
  {
    path: 'report/patrol',
    name: 'PatrolReport',
    component: () => import('@/views/patrol-strategy/PatrolStrategyList'),
    meta: {
      title: '巡检报表',
      icon: 'patrol',
      requireApiPermission: ['ops.patrol_strategy.view']
    }
  },
  {
    path: 'report/patrol/create',
    name: 'PatrolStrategyCreate',
    meta: {
      title: '创建巡检策略',
      hidden: true,
      requireApiPermission: ['ops.patrol_strategy.create']
    },
    component: () => import('@/views/patrol-strategy/PatrolStrategyCreate')
  },
  {
    path: 'report/patrol/:id/edit',
    name: 'PatrolStrategyEdit',
    meta: {
      title: '编辑巡检策略',
      hidden: true,
      requireApiPermission: ['ops.patrol_strategy.update']
    },
    component: () => import('@/views/patrol-strategy/PatrolStrategyCreate')
  },
  {
    path: 'report/patrol/:id/report',
    name: 'PatrolStrategyReport',
    meta: {
      title: '巡检报表详情',
      hidden: true,
      requireApiPermission: ['ops.patrol_strategy.view']
    },
    component: () => import('@/views/patrol-strategy/PatrolStrategyReport')
  },
  {
    path: 'report/assets',
    name: 'AssetsReport',
    component: () => import('@/layouts/BlankLayout'),
    meta: {
      title: '资产报表',
      icon: 'asset_report'
    },
    children: [
      {
        path: 'statistics',
        name: 'AssetsStatisticsReport',
        component: () => import('@/views/source-report/SourceReportList'),
        meta: {
          title: '资源统计',
          requireApiPermission: ['base.source_report.view']
        }
      },
      {
        path: 'statistics/:id/detail',
        name: 'AssetsStatisticsReportDetail',
        meta: {
          title: '资源统计详情',
          hidden: true,
          requireApiPermission: ['base.source_report.view']
        },
        component: () => import('@/views/source-report/SourceReportDetail')
      },
      {
        path: 'usage',
        name: 'AssetsUsageReport',
        meta: {
          title: '资源使用',
          requireApiPermission: ['base.source_report.view']
        },
        component: () => import('@/views/source-usage-report/SourceUsageReportList')
      },
      {
        path: 'usage/:id/detail',
        name: 'AssetsUsageReportDetail',
        meta: {
          title: '资源使用详情',
          hidden: true,
          requireApiPermission: ['base.source_report.view']
        },
        component: () => import('@/views/source-usage-report/SourceUsageReportDetail')
      },
      {
        path: 'expiration',
        name: 'Expiration',
        meta: {
          title: '到期提醒',
          requireApiPermission: ['base.expiration.view']
        },
        component: () => import('@/views/Expiration')
      }
    ]
  },
  {
    path: 'report/service',
    name: 'ServiceReport',
    component: () => import('@/views/service-report/ServiceReportList'),
    meta: {
      title: '业务报表',
      icon: 'bussiness_report',
      requireModule: 'service',
      requireApiPermission: ['service.service_report.view']
    }
  },
  {
    path: 'report/service/:id/detail',
    name: 'ServiceReportDetail',
    meta: {
      title: '业务报表详情',
      hidden: true,
      requireModule: 'service',
      requireApiPermission: ['service.service_report.view']
    },
    component: () => import('@/views/service-report/ServiceReportDetail')
  },
  {
    path: 'report/network-interface-usage',
    name: 'NetworkInterfaceUsageReport',
    meta: {
      title: '闲置网口报表',
      icon: 'network_interface',
      requireApiPermission: ['base.network_interface_usage_report.view']
    },
    component: () => import('@/views/network-interface-usage-report/NetworkInterfaceUsageReportList')
  },
  {
    path: 'report/network-interface-usage/:id/detail',
    name: 'NetworkInterfaceUsageReportDetail',
    meta: {
      title: '闲置网口报表详情',
      hidden: true,
      requireApiPermission: ['base.network_interface_usage_report.view']
    },
    component: () => import('@/views/network-interface-usage-report/NetworkInterfaceUsageReportDetail')
  }
]

export default reportRoutes
