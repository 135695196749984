<template>
  <a-layout class="login-container">
    <a-layout-content
      style="display: flex; align-items: center; justify-content: center"
    >
      <a-card
        :body-style="{ padding: '0' }"
        :bordered="false"
        :hoverable="true"
        style="width: 1000px; height: 600px; background-color: #fff; z-index: 9"
      >
        <div
          class="left-container"
          style="
            width: 440px;
            float: left;
            border-radius: 8px 0 0 8px;
            height: 600px;
            color: #fff;
          "
        >
          <div
            style="
              font-size: 28px;
              font-weight: 800;
              text-align: center;
              margin-top: 200px;
              line-height: 48px;
            "
          >
            欢迎使用
          </div>
          <div
            style="
              font-size: 28px;
              font-weight: 600;
              text-align: center;
              line-height: 64px;
            "
          >
            {{ title }}
          </div>
        </div>

        <div
          style="
            float: right;
            border-radius: 8px;
            width: 560px;
            padding: 80px;
            height: 600px;
          "
        >
          <div style="text-align: center; margin: 32px 0px">
            <img src="@/assets/images/logo.png" alt="Logo" style="max-width: 200px; max-height: 60px">
          </div>
          <router-view></router-view>
        </div>
      </a-card>
    </a-layout-content>
    <layout-footer style="background: transparent"></layout-footer>
  </a-layout>
</template>

<script>
import LayoutFooter from '@/components/LayoutFooter.vue'
import config from '@/config'

export default {
  name: 'AuthLayout',
  components: {
    LayoutFooter
  },
  data () {
    return {
      title: config.title
    }
  }
}
</script>

<style lang="less">
.login-container {
  position: relative;
  min-height: 100vh !important;
  // &::after{
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   display: inline-block;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   // background-image: url('../assets/images/background.png') !important;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position: bottom left;
  //   // opacity: 0.65;
  //   filter:brightness(.8);
  //   z-index: 0;
  // }
  background-image: linear-gradient(
      127deg,
      rgba(0, 4, 255, 0.04),
      rgba(0, 255, 0, 0) 70%
    ),
    linear-gradient(336deg, rgba(255, 0, 0, 0.04), rgba(0, 0, 255, 0) 60%) !important;

  .left-container {
    background-image: url('../assets/images/background-1.png') !important;
    background-repeat: no-repeat !important;
    background-position-x: 90%;
    background-size: cover;
  }
}
</style>
