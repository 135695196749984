import qs from 'qs'
import request from '@/utils/request'

const urlPrefix = '/databases'

export function getDatabase (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateDatabase (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteDatabase (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getDatabaseMonitorStatusList (id) {
  return request.get(`${urlPrefix}/${id}/monitor/statuses`)
}

export function getDatabaseMonitorItemList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/items`, { params })
}

export function getDatabaseMonitorHistory (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/history`, { params })
}

export function getDatabaseMonitorTriggerList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/triggers`, { params })
}

export function updateDatabaseMonitorTrigger (id, data) {
  return request.post(`${urlPrefix}/${id}/monitor/triggers`, data)
}

export function getDatabaseMonitorMacro (id) {
  return request.get(`${urlPrefix}/${id}/monitor/macros`)
}

export function getDatabaseList (params) {
  return request.get(urlPrefix, { params })
}

export function createDatabase (data) {
  return request.post(urlPrefix, data)
}

export function getDatabaseCount (params) {
  return request.get(`${urlPrefix}/count`, { params })
}

export function syncDatabase (data) {
  return request.post(`${urlPrefix}/sync`, data)
}

export function monitorDatabase (data) {
  return request.post(`${urlPrefix}/monitor`, data)
}

export function exportDatabase (params) {
  return request.get(`${urlPrefix}/export`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    },
    responseType: 'blob'
  })
}

export function getDatabaseGroup (id) {
  return request.get(`${urlPrefix}/groups/${id}`)
}

export function updateDatabaseGroup (id, data) {
  return request.put(`${urlPrefix}/groups/${id}`, data)
}

export function deleteDatabaseGroup (id) {
  return request.delete(`${urlPrefix}/groups/${id}`)
}

export function getDatabaseGroupList () {
  return request.get(`${urlPrefix}/groups/tree`)
}

export function createDatabaseGroup (data) {
  return request.post(`${urlPrefix}/groups`, data)
}

export function testDatabase (data) {
  return request.post(`${urlPrefix}/test`, data)
}

export function getPerformanceDatabaseList (params) {
  return request.get(`${urlPrefix}/performance`, { params })
}

export function getDatabaseHealthScore (id) {
  return request.get(`${urlPrefix}/${id}/health/score`)
}

export function getDatabaseAlertList (params) {
  return request.get(`${urlPrefix}/alerts`, { params })
}

export function getDatabaseAlertCount (params) {
  return request.get(`${urlPrefix}/alerts/count`, { params })
}
