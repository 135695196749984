import qs from 'qs'
import request from '@/utils/request'

const urlPrefix = '/domains'

export function getDomain (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateDomain (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteDomain (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getDomainList (params) {
  return request.get(urlPrefix, { params })
}

export function createDomain (data) {
  return request.post(urlPrefix, data)
}

export function syncDomain (data) {
  return request.post(`${urlPrefix}/sync`, data)
}

export function exportDomain (params) {
  return request.get(`${urlPrefix}/export`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    },
    responseType: 'blob'
  })
}

export function getDomainGroup (id) {
  return request.get(`${urlPrefix}/groups/${id}`)
}

export function updateDomainGroup (id, data) {
  return request.put(`${urlPrefix}/groups/${id}`, data)
}

export function deleteDomainGroup (id) {
  return request.delete(`${urlPrefix}/groups/${id}`)
}

export function getDomainGroupList () {
  return request.get(`${urlPrefix}/groups/tree`)
}

export function createDomainGroup (data) {
  return request.post(`${urlPrefix}/groups`, data)
}

export function getDomainAlertList (params) {
  return request.get(`${urlPrefix}/alerts`, { params })
}
