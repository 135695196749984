<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view v-if="isRouterAlive"></router-view>
    </div>
  </a-config-provider>
</template>

<script>
import moment from 'moment'
import 'moment/locale/zh-cn'
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN'
import { getLocaleZhCN } from '@/api/base'

moment.locale('zh-cn')

export default {
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      locale: zhCN,
      isRouterAlive: true
    }
  },
  created () {
    getLocaleZhCN().then(res => {
      this.$i18n.mergeLocaleMessage('zh', res.data)
    })
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="less">
#app {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('./assets/images/header-bg-1.png');
    background-repeat: repeat-x;
    background-attachment: fixed;
    background-position: bottom right;
    background-size: 65% 70%;
    filter: blur(0.1px);
    opacity: 0.5;
    z-index: 0;
  }

  .page-header {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-image: url('./assets/images/page-header-4.png');
      background-repeat: no-repeat;
      background-position-y: 29%;
      background-size: cover;
      border-radius: 8px;
      filter: hue-rotate(15deg);
    }

    .ant-page-header-heading-title,
    .ant-page-header-content {
      position: relative;
      z-index: 999;
      color: rgba(255, 255, 255, 0.85) !important;
    }
  }

  .ant-page-header {
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  }

  .ant-table-placeholder {
    border: none;
    margin-top: 4px;
  }

  .ant-card-hoverable {
    cursor: default;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .vertical-form {
    .ant-form-item {
      margin-bottom: 8px;
    }
  }

  .ant-card {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);

    .ant-card-head {
      position: relative;
      padding-left: 16px !important;
      overflow: hidden;

      .ant-card-head-wrapper::before {
        position: absolute;
        content: '';
        display: block;
        background: #096dd9;
        height: 40%;
        width: 6px;
        left: -3px;
        border-radius: 8px;
      }
    }
  }

  .inline-card {
    box-shadow: none;
  }

  .ant-card-small > .ant-card-head {
    border-bottom: none;
  }

  .ant-table {
    .resize-table-th {
      position: relative;
      user-select: none;

      .table-draggable-handle {
        height: 100% !important;
        bottom: 0;
        right: 0;
        cursor: col-resize;
        touch-action: none;
        position: absolute;
        transform: none !important;
        background: #f5f9fe;
      }
    }

    .ant-table-fixed-left table,
    .ant-table-fixed-right table {
      width: min-content;
    }
  }

  .ant-table-small {
    border: none;
  }

  .text-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .text-overflow-multiline {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .ant-radio-group,
  .ant-radio-group-small,
  .detail-drawer {
    border-radius: 16px;
    background: #f7f7f7;

    .ant-radio-button-wrapper {
      padding: 0 0px;
      line-height: 32px;
      background: none;
      border: none;
      border-radius: 16px;
      height: 32px;
      width: 98px;
      text-align: center;
      color: #686e7e;
      font-size: 14px;

      &::before {
        width: 0;
      }
    }

    .ant-radio-button-wrapper-checked {
      background: #096dd9;
      border-radius: 16px;
      color: #fff;
      font-weight: 500;
    }
  }

  .ant-collapse-header {
    padding: 12px 8px;

    .ant-collapse-arrow {
      right: 0;
      text-align: right;
    }
  }

  // 改版
  .module-header {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 25px;
    position: relative;
    padding-left: 8px;

    &::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 20px;
      background: #096dd9;
      border-radius: 2px;
      top: 2.5px;
      left: 0px;
    }
  }
}

.text-overflow-multiline {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.module-header-fs14 {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
  position: relative;
  padding-left: 8px;

  &::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 16px;
    background: #096dd9;
    border-radius: 2px;
    top: 2px;
    left: 0px;
  }
}

.custom-table {
  .ant-table {
    border-radius: 8px;
    position: relative;

    .resize-table-th {
      position: relative;
      user-select: none;

      .table-draggable-handle {
        height: 100% !important;
        bottom: 0;
        right: 0;
        cursor: col-resize;
        touch-action: none;
        position: absolute;
        transform: none !important;
        background: #f5f9fe;
      }
    }

    .ant-table-content {
      .ant-table-placeholder {
        border: none;
      }

      .ant-table-thead > tr > th {
        background: #e6f0fb !important;
        height: 48px !important;
        padding: 0 16px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        border: none;
        border-radius: 0;
      }

      .ant-table-tbody > tr {
        background: #fff;
        height: 56px;
        max-height: 56px;

        &:nth-of-type(2n) {
          background: #f5f9fe;
        }

        & > td {
          border: none !important;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
        }
      }

      .ant-table-fixed-columns-in-body:not([colspan]) > * {
        display: none;
      }

      .ant-pagination {
        .ant-pagination-item a,
        i {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: rgba(0, 0, 0, 0.88);
        }

        .ant-pagination-total-text {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: rgba(0, 0, 0, 0.5);
          line-height: 32px;
          margin-right: 16px;
        }
      }
    }

    .ant-btn {
      border: none;
      max-height: 22px;
      line-height: 1.5;
    }

    .ant-tag {
      // display: block;
      text-align: center;
      height: 22px;
      max-height: 22px;
      box-sizing: border-box;
      // overflow: hidden;
    }
  }
}

.small-custom-table {
  .ant-table-tbody > tr {
    height: 48px !important;
    max-height: 48px !important;

    & > td {
      padding: 8px 16px !important;
    }
  }
}

.detail-drawer {
  .ant-radio-group,
  .ant-radio-group-small {
    border-radius: 16px;
    background: #f7f7f7;

    .ant-radio-button-wrapper {
      padding: 0 0px;
      line-height: 32px;
      background: none;
      border: none;
      border-radius: 16px;
      height: 32px;
      width: 98px;
      text-align: center;
      color: #686e7e;
      font-size: 14px;

      &::before {
        width: 0;
      }
    }

    .ant-radio-button-wrapper-checked {
      background: #096dd9;
      border-radius: 16px;
      color: #fff;
      font-weight: 500;
    }
  }
}

// 修改滚动条
.overflow-scroll-hidden {
  scrollbar-width: thin;
  scrollbar-color: #e2e2e2 #ffffff00;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(223, 223, 223, 0.386);
    border-radius: 10px;
  }
}

// 隐藏滚动条
.overflow-scroll-none {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

// table 隐藏滚动条
.scroll-hidden-table {
  .ant-table-body {
    scrollbar-width: thin;
    scrollbar-color: #e2e2e2 #ffffff00;

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      background: #f5f9fd;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e2e2e2;
      border-radius: 10px;
    }
  }
}

.ant-descriptions-item-content {
  overflow-wrap: anywhere;
}

.description-overflow-hidden .ant-descriptions-item {
  display: flex;
  flex-wrap: nowrap;

  .ant-descriptions-item-label {
    white-space: nowrap;
  }

  .ant-descriptions-item-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.description-in-modal {
  .description-overflow-hidden .ant-descriptions-item {
    .ant-descriptions-item-content {
      white-space: normal;
    }
  }
}

.description-item-12px {
  .ant-descriptions-row > th, .ant-descriptions-row > td {
    padding-bottom: 12px;
  }
}
// .ant-table-fixed-header .ant-table-scroll .ant-table-header {
//   margin: 0 -20px -20px 0 !important;
//   padding: 0 !important;
// }
</style>
