import request from '@/utils/request'

const urlPrefix = '/settings'

export function getEmailSetting () {
  return request.get(`${urlPrefix}/email`)
}

export function updateEmailSetting (data) {
  return request.put(`${urlPrefix}/email`, data)
}

export function testEmailSetting (data) {
  return request.post(`${urlPrefix}/email/test`, data)
}

export function getSecuritySetting () {
  return request.get(`${urlPrefix}/security`)
}

export function updateSecuritySetting (data) {
  return request.put(`${urlPrefix}/security`, data)
}

export function getMachineCode () {
  return request.get(`${urlPrefix}/machine-code`)
}

export function getLicense () {
  return request.get(`${urlPrefix}/license`)
}

export function updateLicense (data) {
  return request.put(`${urlPrefix}/license`, data)
}

export function getSystemSetting () {
  return request.get(`${urlPrefix}/system`)
}
