import qs from 'qs'
import request from '@/utils/request'

const urlPrefix = '/middlewares'

export function getMiddleware (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateMiddleware (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteMiddleware (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getMiddlewareMonitorStatusList (id) {
  return request.get(`${urlPrefix}/${id}/monitor/statuses`)
}

export function getMiddlewareMonitorItemList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/items`, { params })
}

export function getMiddlewareMonitorHistory (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/history`, { params })
}

export function getMiddlewareMonitorTriggerList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/triggers`, { params })
}

export function updateMiddlewareMonitorTrigger (id, data) {
  return request.post(`${urlPrefix}/${id}/monitor/triggers`, data)
}

export function getMiddlewareMonitorMacro (id) {
  return request.get(`${urlPrefix}/${id}/monitor/macros`)
}

export function getMiddlewareList (params) {
  return request.get(urlPrefix, { params })
}

export function createMiddleware (data) {
  return request.post(urlPrefix, data)
}

export function getMiddlewareCount (params) {
  return request.get(`${urlPrefix}/count`, { params })
}

export function syncMiddleware (data) {
  return request.post(`${urlPrefix}/sync`, data)
}

export function monitorMiddleware (data) {
  return request.post(`${urlPrefix}/monitor`, data)
}

export function exportMiddleware (params) {
  return request.get(`${urlPrefix}/export`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    },
    responseType: 'blob'
  })
}

export function getMiddlewareGroup (id) {
  return request.get(`${urlPrefix}/groups/${id}`)
}

export function updateMiddlewareGroup (id, data) {
  return request.put(`${urlPrefix}/groups/${id}`, data)
}

export function deleteMiddlewareGroup (id) {
  return request.delete(`${urlPrefix}/groups/${id}`)
}

export function getMiddlewareGroupList () {
  return request.get(`${urlPrefix}/groups/tree`)
}

export function createMiddlewareGroup (data) {
  return request.post(`${urlPrefix}/groups`, data)
}

export function testMiddleware (data) {
  return request.post(`${urlPrefix}/test`, data)
}

export function getMiddlewareHealthScore (id) {
  return request.get(`${urlPrefix}/${id}/health/score`)
}

export function getMiddlewareAlertList (params) {
  return request.get(`${urlPrefix}/alerts`, { params })
}

export function getMiddlewareAlertCount (params) {
  return request.get(`${urlPrefix}/alerts/count`, { params })
}
